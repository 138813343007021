import { DropdownModel } from '@common/models/geral/DropdownModel.js';
import _ from 'lodash';
export class ItemModel {
  constructor({
    id,
    codigo,
    descricao,
    cest,
    valorCompra,
    valorFrete,
    valorVenda,
    prazoEntrega,
    codigoServicoLeiComplementar,
    alterarResultados,
    tempoExecucao,
    aliquotaIcms,
    descricaoCompleta,
    tipoItem,
    categoriaItem,
    participanteFabricante,
    classificacaoFiscal,
    unidadeMedida,
    localEstocagem,
    itemTipoInstrumentoModeloInstrumento = [],
  }) {
    this.id = id;
    this.codigo = codigo;
    this.descricao = descricao;
    this.cest = cest;
    this.valorCompra = valorCompra;
    this.valorFrete = valorFrete;
    this.valorVenda = valorVenda;
    this.prazoEntrega = prazoEntrega;
    this.codigoServicoLeiComplementar = codigoServicoLeiComplementar;
    this.alterarResultados = alterarResultados;
    this.tempoExecucao = tempoExecucao;
    this.aliquotaIcms = aliquotaIcms;
    this.descricaoCompleta = descricaoCompleta;
    this.tipoItem = tipoItem;
    if (tipoItem) this.tipoItem = new DropdownModel(tipoItem);
    this.categoriaItem = categoriaItem;
    if (categoriaItem) this.categoriaItem = new DropdownModel(categoriaItem);
    this.participanteFabricanteId = participanteFabricante;
    if (participanteFabricante)
      this.participanteFabricante = new DropdownModel(participanteFabricante);
    this.classificacaoFiscal = classificacaoFiscal;
    if (classificacaoFiscal)
      this.classificacaoFiscal = new DropdownModel(classificacaoFiscal);
    if (unidadeMedida) this.unidadeMedida = new DropdownModel(unidadeMedida);
    this.localEstocagem = localEstocagem;
    if (localEstocagem) this.localEstocagem = new DropdownModel(localEstocagem);
    this.itemTipoInstrumentoModeloInstrumento =
      itemTipoInstrumentoModeloInstrumento;
    this.itemTipoInstrumentoModeloInstrumento?.forEach((item) => {
      item.tipoInstrumentoId = item.tipoInstrumento?.id;
      item.modeloInstrumentoId = item.modeloInstrumento?.id;
    });
  }

  get request() {
    const retorno = _.cloneDeep(this);

    retorno.participanteFabricanteId = retorno.participanteFabricante?.value;
    retorno.unidadeMedidaId = retorno.unidadeMedida?.value;
    retorno.classificacaoFiscalId = retorno.classificacaoFiscal?.value;
    retorno.localEstocagemId = retorno.localEstocagem?.value;
    retorno.categoriaItemId = retorno.categoriaItem?.value;
    retorno.tipoItemId = retorno.tipoItem?.value;
  

    return retorno;
  }
}
