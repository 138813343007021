<template>
  <div>
    <div class="row d-flex">
      <div class="titulo-pagina col-12 col-md-8 mt-4">
        {{ $t('modulos.operacao.formulario.abas.analise_tecnica_em_lote') }}
      </div>
    </div>
    <div class="d-flex mb-2">
      <v-chip class="mr-1" small>
        {{ $t('modulos.operacao.steps.ordem_servico') }}:
        {{ `${codigoOrdemServico}` }}
      </v-chip>
      <v-chip
        v-if="form.arrayInstrumentosIds?.length && e1 != 1"
        class="mr-1"
        small
      >
        Qtde de instrumentos:
        {{ `${form.arrayInstrumentosIds?.length}` }}
      </v-chip>
      <v-chip
        v-if="form.arrayInstrumentosIds?.length && e1 != 1"
        class="mr-1"
        small
      >
        Tipo de Instrumento:
        {{ form.tipoInstrumento }}
      </v-chip>
    </div>
    <v-stepper v-model="e1" style="box-shadow: none">
      <v-stepper-header style="box-shadow: none">
        <v-stepper-step step="1" editable>
          {{ $t('modulos.operacao.steps.instrumentos') }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step step="2" editable>
          {{ $t('modulos.operacao.steps.calibracao') }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step step="3" :editable="e1 != 1">
          {{ $t('modulos.operacao.steps.checklist') }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step step="4" :editable="e1 != 1">
          {{ $t('modulos.operacao.steps.manutencao') }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step step="5" :editable="e1 != 1">
          {{ $t('modulos.operacao.steps.pecas') }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step step="6" :editable="e1 != 1">
          {{ $t('modulos.operacao.steps.resumo') }}
        </v-stepper-step>
      </v-stepper-header>

      <div
        v-if="e1 !== 6 && e1 != 1"
        class="row mt-3 mr-3"
        style="justify-content: end"
      >
        <botao-padrao
          v-if="e1 == 5"
          class="mr-2"
          @click="abreModalNovoItemPeca"
        >
          {{ $t('modulos.operacao.formulario.novo_item_peca') }}
        </botao-padrao>
        <botao-padrao class="mr-3" @click="abreModalRequisitos">
          {{ $t('modulos.operacao.formulario.abas.requisitos') }}
        </botao-padrao>
      </div>

      <v-stepper-items>
        <v-stepper-content step="1">
          <InstrumentosListagemAnaliseLote
            ref="instrumentos-listagem"
            :form="form"
            :instrumentos-analise-tecnica-em-lote="
              instrumentosAnaliseTecnicaEmLote
            "
            :ordem-servico-id="ordemServico"
            @proximo-step="mudaStep"
            @envia-tipo-instrumento="enviaTipoInstrumento"
            @atualiza-dados-analise-lote="atualizaDadosAnaliseLote"
            @atualiza-listagem-instrumentos="
              atualizaListagemInstrumentosAtualizados
            "
          />
        </v-stepper-content>
        <v-stepper-content step="2">
          <dados-calibracao-em-lote
            ref="dados-analise-tecnica"
            :form="form"
            :atualiza-dados-analise-lote-instrumentos="
              atualizaDadosAnaliseLoteInstrumentos
            "
            :id-tipo-calibracao="form.tipoCalibracaoId"
            :flag-servico-calibracao="form.flagTemServicoCalibracao"
            :desabilita-garantia="desabilitaGarantia"
            :participante-id="participanteId"
            :tipo-instrumento-id-selecionado="tipoInstrumentoIdSelecionado"
            :instrumento-id="instrumentoId"
            :nome-tipo-instrumento="nomeTipoInstrumento"
            :codigo-instrumento="codigoInstrumento"
            :codigo-ordem-servico="codigoOrdemServico"
            :ordem-servico-id="ordemServico"
            :opcoes-tipo-calibracao="opcoesTipoCalibracao"
            :opcoes-local-execucao="opcoesLocalExecucao"
            :atualiza-listagem-calibracao="atualizaListagemCalibracao"
            :dados-calibracao-selecionados="dadosCalibracaoSelecionados"
            :local-execucao-instalacao-cliente="localExecucaoInstalacaoCliente"
            :cliente-participante-ordem-servico-id="
              clienteParticipanteOrdemServicoId
            "
            :instrumentos-analise-tecnica-em-lote="
              instrumentosAnaliseTecnicaEmLote
            "
            @volta-step="mudaStep"
            @proximo-step="mudaStep"
            @valida-dados-analise-tecnica="validaFormularioDadosAnaliseTecnica"
            @atualiza-radio-checklist="atualizaRadioChecklist"
          />
        </v-stepper-content>
        <v-stepper-content step="3">
          <checklist-em-lote
            ref="checklist"
            :form="form"
            :participante-id="participanteId"
            :instrumento-id="instrumentoId"
            :atualiza-radio-check="atualizaRadioCheck"
            :nome-tipo-instrumento="nomeTipoInstrumento"
            :codigo-instrumento="codigoInstrumento"
            :codigo-ordem-servico="codigoOrdemServico"
            :dados-checklist-selecionado="dadosChecklistSelecionado"
            @volta-step="mudaStep"
            @proximo-step="mudaStep"
            @envia-servicos-checklist="servicosChecklist"
            @envia-pecas-checklist="pecasChecklist"
          />
        </v-stepper-content>
        <v-stepper-content step="4">
          <listagem-manutencao-em-lote
            ref="listagem-manutencao"
            :form="form"
            :participante-id="participanteId"
            :instrumento-id="instrumentoId"
            :nome-tipo-instrumento="nomeTipoInstrumento"
            :codigo-instrumento="codigoInstrumento"
            :codigo-ordem-servico="codigoOrdemServico"
            :servicos-ids="servicosIds"
            :dados-manutencao-selecionados="dadosManutencaoSelecionados"
            :instrumentos-analise-tecnica-em-lote="
              instrumentosAnaliseTecnicaEmLote
            "
            @volta-step="mudaStep"
            @proximo-step="mudaStep"
          />
        </v-stepper-content>
        <v-stepper-content step="5">
          <listagem-pecas-em-lote
            ref="listagem-pecas"
            :form="form"
            :participante-id="participanteId"
            :instrumento-id="instrumentoId"
            :nome-tipo-instrumento="nomeTipoInstrumento"
            :id-tipo-instrumento="idTipoInstrumento"
            :codigo-instrumento="codigoInstrumento"
            :codigo-ordem-servico="codigoOrdemServico"
            :pecas-ids="pecasIds"
            :dados-pecas-selecionadas="dadosPecasSelecionadas"
            :atualiza-listagem-pecas="atualizaListagemPecas"
            @volta-step="mudaStep"
            @proximo-step="mudaStep"
          />
        </v-stepper-content>
        <v-stepper-content step="6">
          <resumo-analise-tecnica-em-lote
            ref="resumo-analise-tecnica"
            :form="form"
            :opcoes-tipo-calibracao="opcoesTipoCalibracao"
            :opcoes-local-execucao="opcoesLocalExecucao"
            :nome-tipo-instrumento="nomeTipoInstrumento"
            :codigo-instrumento="codigoInstrumento"
            :codigo-ordem-servico="codigoOrdemServico"
            :atualiza-listagem-resumo="atualizaListagemResumo"
            :instrumentos-analise-tecnica-em-lote="
              instrumentosAnaliseTecnicaEmLote
            "
            @volta-step="mudaStep"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end mt-4">
      <botao-padrao outlined color="secondary" class="mr-2" @click="cancelar">
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao class="mr-2" @click="salvar(false)">
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
      <botao-padrao @click="salvar(true)">
        <v-icon>$mdiCheck</v-icon>
        {{ $t('geral.botoes.finalizar') }}
      </botao-padrao>
    </div>
    <requisito-modal
      ref="modal-requisito"
      :requisitos-cliente="dadosRequisitosClienteFormatado"
    />
    <instrumento-edicao-modal
      ref="modal-edicao-instrumento"
      :instrumento-id="instrumentoId"
      :atualiza="true"
      @atualiza-instrumento-analise-tecnica="atualizaInstrumentoAnaliseTecnica"
    />
    <criterios-aceitacao-modal
      ref="modal-criterios-aceitacao"
      :instrumento-id="instrumentoId"
    />
    <novo-item-peca-modal
      ref="modal-novo-item-peca"
      :id-tipo-instrumento="idTipoInstrumento"
      :nome-tipo-instrumento="nomeTipoInstrumento"
      :id-modelo-instrumento="idModeloInstrumento"
      :nome-modelo-instrumento="nomeModeloInstrumento"
      :id-unidade-medida-instrumento="idUnidadeMedidaInstrumento"
      :nome-unidade-medida-instrumento="nomeUnidadeMedidaInstrumento"
      @atualizaListagemPeca="atualizaListagemPeca"
    />
  </div>
</template>
<script>
import InstrumentosListagemAnaliseLote from './InstrumentosListagemAnaliseLote';
import OrdemServicoService from '@common/services/cadastros/OrdemServicoService';
import ParticipanteService from '@common/services/cadastros/ParticipanteService';
import UtilsService from '@common/services/UtilsService';
import TipoCalibracaoService from '@common/services/cadastros/TipoCalibracaoService';
import { DropdownModel } from '@common/models/geral/DropdownModel';
import { AnaliseTecnicaModelEmLote } from '@common/models/cadastros/AnaliseTecnicaModelEmLote';
import DadosCalibracaoEmLote from './DadosCalibracaoEmLote';
import ListagemManutencaoEmLote from './ListagemManutencaoEmLote';
import ChecklistEmLote from './ChecklistEmLote';
import ListagemPecasEmLote from './ListagemPecasEmLote';
import ResumoAnaliseTecnicaEmLote from './ResumoAnaliseTecnicaEmLote';
import { RequisitoModal } from '@components/requisitos';
import InstrumentoEdicaoModal from './modais/InstrumentoEdicaoModal.vue';
import CriteriosAceitacaoModal from './modais/CriteriosAceitacaoModal.vue';
import AnaliseTecnicaService from '@common/services/cadastros/AnaliseTecnicaService';
import NovoItemPecaModal from './modais/NovoItemPecaModal.vue';
import _ from 'lodash';

export default {
  components: {
    DadosCalibracaoEmLote,
    ListagemManutencaoEmLote,
    InstrumentosListagemAnaliseLote,
    ListagemPecasEmLote,
    ResumoAnaliseTecnicaEmLote,
    RequisitoModal,
    InstrumentoEdicaoModal,
    ChecklistEmLote,
    CriteriosAceitacaoModal,
    NovoItemPecaModal,
  },
  props: ['idInstrumento', 'ordemServico', 'id'],
  data() {
    return {
      percentualTotal: null,
      tipoInstrumentoIdSelecionado: '',
      instrumentosAnaliseTecnicaEmLote: [],
      formEditar: {},
      formEnviar: {},
      dadosCalibracaoSelecionados: [],
      dadosChecklistSelecionado: [],
      dadosManutencaoSelecionados: [],
      dadosPecasSelecionadas: [],
      servicosIds: [],
      pecasIds: [],
      opcoesLocalExecucao: null,
      desabilitaGarantia: null,
      atualizaListagemCalibracao: 1,
      atualizaListagemPecas: 1,
      atualizaListagemResumo: 1,
      atualizaRadioCheck: 1,
      atualizaDadosAnaliseLoteInstrumentos: 1,
      opcoesTipoCalibracao: null,
      idModeloInstrumento: null,
      nomeModeloInstrumento: null,
      idUnidadeMedidaInstrumento: null,
      nomeUnidadeMedidaInstrumento: null,
      dadosRequisitosClienteFormatado: null,
      participanteId: null,
      instrumentoId: null,
      codigoInstrumento: null,
      nomeTipoInstrumento: null,
      idTipoInstrumento: null,
      codigoOrdemServico: null,
      clienteParticipanteOrdemServicoId: null,
      formInstrumento: null,
      ehEdicao: false,
      localExecucaoInstalacaoCliente: false,
      instalacoesClienteId: [],
      peloMenosUmInstrumentoTemServicoCalibracao: [],
      e1: 1,
      form: new AnaliseTecnicaModelEmLote({
        calibracao: {},
        manutencoes: [],
        pecas: [],
      }),
    };
  },
  computed: {},
  watch: {
    e1(newValue, oldValue) {
      if (oldValue == 1)
        this.$refs['instrumentos-listagem'].validaTiposInstrumentosIguais();
      if (oldValue == 2 && newValue != 1)
        this.$refs['dados-analise-tecnica'].validaFormulario(false);
      if (oldValue == 4 && newValue > 4)
        this.$refs['listagem-manutencao'].proximoStep(false);
      if (oldValue == 5 && newValue > 5)
        this.$refs['listagem-pecas'].proximoStep(false);
      if (this.e1 == 6) this.atualizaListagemResumo++;
    },
  },
  mounted() {
    if (this.ordemServico) {
      this.buscaDadosAnaliseTecnicaEmLote();
      this.form.ordemServicoId = this.ordemServico;
    } else {
      this.form.flagSemConserto = 6;

      this.buscaDadosOrdemServico();
    }

    this.listarLocalExecucao();
    this.listarTipoCalibracao();
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.operacao.titulos.analise_tecnica_em_lote')
    );
  },
  methods: {
    atualizaListagemInstrumentosAtualizados() {
      this.buscaDadosOrdemServico();
    },
    atualizaDadosAnaliseLote() {
      this.atualizaDadosAnaliseLoteInstrumentos++;
    },
    atualizaRadioChecklist() {
      this.atualizaRadioCheck++;
    },
    buscaDadosAnaliseTecnicaEmLote() {
      this.$store.dispatch('Layout/iniciarCarregamento');
      AnaliseTecnicaService.buscarAnaliseTecnicaEmLote(this.ordemServico)
        .then((res) => {
          this.form.analisesTecnicas = [];
          this.form.arrayInstrumentosIds = [];
          let itensNaoFinalizados = res.data.filter(
            (item) => !item.flagFinalizada
          );
          if (itensNaoFinalizados?.length) {
            this.ehEdicao = true;
            itensNaoFinalizados.forEach((item) => {
              this.form.analisesTecnicas.push({
                analiseTecnicaId: item.id,
                instrumentoId: item.instrumento.id,
                ordemServicoId: item.ordemServico.id,
                flagTemServicoCalibracao: item.flagTemServicoCalibracao,
                prazoEntregaEmDias: item.prazoEntregaEmDias,
                flagGarantia: false,
                justificativaGarantia: null,
                localExecucaoId: item.localExecucao.id,
                valorTotal: item.valorTotal,
                flagSemConserto: item.flagSemConserto,
                justificativaSemConserto: item.justificativaSemConserto,
                tipoPrazoEntrega: item.tipoPrazoEntrega,
                flagFinalizada: item.flagFinalizada,

                calibracao: item.calibracao,
                checklist: _.sortBy(item.checklists, 'sequencia'),
                manutencoes: [],
                pecas: [],
              });
              this.form.arrayInstrumentosIds.push(item.instrumento.id);
            });

            itensNaoFinalizados.forEach((itemData) => {
              this.form.analisesTecnicas.map((item) => {
                if (
                  itemData.instrumento.id == item.instrumentoId &&
                  itemData.manutencoes?.length
                ) {
                  itemData.manutencoes.forEach((itemManutencao) => {
                    item.manutencoes.push({
                      id: itemManutencao.id,
                      servico: {
                        id: itemManutencao.servicoId,
                        tempoExecucao: itemManutencao.tempoExecucao,
                      },
                    });
                  });
                }
              });
            });
            itensNaoFinalizados.forEach((itemData) => {
              this.form.analisesTecnicas.map((item) => {
                if (
                  itemData.instrumento.id == item.instrumentoId &&
                  itemData.pecas?.length
                ) {
                  itemData.pecas.forEach((itemPeca) => {
                    item.pecas.push({
                      id: itemPeca.id,
                      pecaId: itemPeca.itemId,
                      quantidadeOrcada: itemPeca.quantidadeOrcada,
                      horasExecucao: itemPeca.tempoExecucao,
                    });
                  });
                }
              });
            });

            this.peloMenosUmInstrumentoTemServicoCalibracao;
            this.peloMenosUmInstrumentoTemServicoCalibracao =
              this.form.analisesTecnicas.filter((item) => item.calibracao);

            if (this.peloMenosUmInstrumentoTemServicoCalibracao.length) {
              this.form.flagTemServicoCalibracao =
                this.peloMenosUmInstrumentoTemServicoCalibracao[0].flagTemServicoCalibracao;
              this.form.tipoCalibracaoId =
                this.peloMenosUmInstrumentoTemServicoCalibracao[0].calibracao.tipoCalibracaoId;
            }

            // this.form.localExecucaoId = itensNaoFinalizados[0].localExecucao.id;
            this.form.tipoPrazoEntrega =
              itensNaoFinalizados[0].tipoPrazoEntrega;
            this.form.prazoEntregaEmDias =
              itensNaoFinalizados[0].prazoEntregaEmDias;

            itensNaoFinalizados.forEach((item) => {
              if (item.calibracao?.servicoId) {
                this.dadosCalibracaoSelecionados.push({
                  servicoId: item.calibracao.servicoId,
                  instrumentoId: item.instrumento.id,
                  horasExecucao: item.calibracao.horasExecucao,
                });
              }
            });
            itensNaoFinalizados.forEach((item) => {
              if (item.manutencoes?.length) {
                item.manutencoes.forEach((itemManutencao) => {
                  this.dadosManutencaoSelecionados.push({
                    servicoId: itemManutencao.servicoId,
                    instrumentoId: item.instrumento.id,
                    tempoExecucao: itemManutencao.tempoExecucao,
                  });
                });
              }
            });
            itensNaoFinalizados.forEach((item) => {
              if (item.pecas?.length) {
                item.pecas.forEach((itemPeca) => {
                  this.dadosPecasSelecionadas.push({
                    pecaId: itemPeca.itemId,
                    instrumentoId: item.instrumento.id,
                    quantidadeOrcada: itemPeca.quantidadeOrcada ?? 1,
                  });
                });
              }
            });

            itensNaoFinalizados.forEach((item) => {
              if (item.checklists.length) {
                this.form.dadosListagemChecklistEdicao = item.checklists;
              }
            });
          }
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');

          this.buscaDadosOrdemServico();
        });
    },
    enviaTipoInstrumento(tipoInstrumento) {
      this.tipoInstrumentoIdSelecionado = tipoInstrumento;
    },
    verificarLocalExecucaoInstalacaoCliente(id) {
      if (!id) return;
      setTimeout(() => {
        if (this.instalacoesClienteId.includes(id)) {
          this.localExecucaoInstalacaoCliente = true;
        }
      }, 200);
    },
    buscaDadosOrdemServico() {
      OrdemServicoService.buscar(this.ordemServico)
        .then((res) => {
          if (res.data) {
            this.clienteParticipanteOrdemServicoId =
              res.data.clienteParticipante?.id;
            this.codigoOrdemServico = res.data.codigo;
            this.participanteId = res.data.solicitanteParticipanteId;
            this.buscaRequisitos(res.data.clienteParticipante?.id);

            this.instrumentosAnaliseTecnicaEmLote =
              res.data.ordensServicosInstrumentos.filter(
                (item) => !item.analiseTecnicaFinalizada
              );
            this.form.localExecucaoId = res.data.localExecucao.id;
            this.verificarLocalExecucaoInstalacaoCliente(
              res.data.localExecucao.id
            );
            this.form.arrayInstrumentosIds.forEach((itemArray) => {
              if (this.instrumentosAnaliseTecnicaEmLote?.length) {
                this.instrumentosAnaliseTecnicaEmLote.forEach((item) => {
                  if (itemArray == item.instrumentoId) {
                    this.tipoInstrumentoIdSelecionado = item.tipoInstrumento.id;
                  }
                });
              }
            });
          }
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        });
    },
    calculaValorTotal() {
      this.form.analisesTecnicas.map((item) => {
        this.valorTotal = 0;
        if (item.calibracao?.valor) {
          this.valorTotal += item.calibracao.valor;
        }

        if (item.manutencoes.length) {
          item.manutencoes.map((item) => {
            if (item.servico?.valor) this.valorTotal += item.servico.valor;
          });
        }

        if (item.pecas.length) {
          item.pecas.map((item) => {
            if (item?.valor) this.valorTotal += item.valor;
          });
        }

        item.valorTotal = this.valorTotal;
      });
    },
    validaManutencaoPecas() {
      const manutecoesValido = this.form.dadosManutencaoResumo?.every(
        (el) => !!parseFloat(el.tempoExecucaoCustom)
      );

      if (!manutecoesValido && this.form.dadosManutencaoResumo) {
        this.mudaStep(4);
        this.toastAlerta(
          this.$t(`modulos.operacao.erros.horas_execuxao_nao_pode_zero`)
        );
        return false;
      }
      const pecasValido = this.form.dadosPecasResumo?.every(
        (el) => !!parseFloat(el.quantidadeOrcada)
      );
      if (!pecasValido && this.form.dadosPecasResumo) {
        this.mudaStep(5);
        this.toastAlerta(
          this.$t(`modulos.operacao.erros.quantidade_orcada_nao_pode_zero`)
        );
        return false;
      }
      return true;
    },
    salvar: async function (flagFinalizada) {
      if (!this.validaManutencaoPecas()) return;
      const calibracaoValida = await this.$refs[
        'dados-analise-tecnica'
      ].validaFormulario(false);
      if (!calibracaoValida) return;

      this.calculaValorTotal();

      this.form.analisesTecnicas.map((item) => {
        item.flagSemConserto = 6;

        item.manutencoes?.forEach((manutecao) => {
          if (!manutecao.servico) return;
          manutecao.servico.tempoExecucao =
            manutecao.servico.tempoExecucaoCustom;
        });

        item.descricaoJustificativaSemConserto =
          this.form.descricaoJustificativaSemConserto;
        if (item.flagSemConserto == 6) {
          item.flagSemConserto = false;
          item.justificativaSemConserto = null;
        } else {
          this.toastAlerta(
            this.$t(
              `modulos.operacao.erros.salvar_sem_concerto_perde_servico_pecas`
            )
          );
        }

        if (item.flagSemConserto == 'SemCalibracao') {
          item.flagSemConserto = true;
          item.justificativaSemConserto = 'SemCalibracao';
        }
        if (item.flagSemConserto == 'SemConserto') {
          item.flagSemConserto = true;
          item.justificativaSemConserto = 'SemConserto';
        }
        if (item.flagSemConserto == 'NaoCompensaFinanceiramente') {
          item.flagSemConserto = true;
          item.justificativaSemConserto = 'NaoCompensaFinanceiramente';
        }
        if (item.flagSemConserto == 'ForaDoEscopoCalibracao') {
          item.flagSemConserto = true;
          item.justificativaSemConserto = 'ForaDoEscopoCalibracao';
        }
        if (item.flagSemConserto == 'ForaDoEscopoManutencao') {
          item.flagSemConserto = true;
          item.justificativaSemConserto = 'ForaDoEscopoManutencao';
        }

        item.flagFinalizada = flagFinalizada;
      });

      if (this.ehEdicao) {
        this.formEditar = {
          analisesTecnicas: [],
        };
        this.form.analisesTecnicas.forEach((item) => {
          this.formEditar.analisesTecnicas.push({
            analiseTecnicaId: item.analiseTecnicaId,
            analiseTecnica: item,
          });
        });
      }
      if (!this.ehEdicao) {
        this.formEnviar = { ...this.form };
      } else {
        this.formEnviar = { ...this.formEditar };
      }

      if (this.localExecucaoInstalacaoCliente) {
        this.formEnviar.prazoEntregaEmDias = 0;
        this.formEnviar.analisesTecnicas.forEach(
          (el) => (el.prazoEntregaEmDias = 0)
        );
      }
      this.$store.dispatch('Layout/iniciarCarregamento');
      AnaliseTecnicaService.salvarEmLote(this.formEnviar, this.ehEdicao)
        .then(() => {
          this.toastSucesso(
            this.$t(`modulos.operacao.analise_tecnica.salvo_com_sucesso`)
          );
          this.$router.push({ name: 'operacao' });
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    abreModalNovoItemPeca() {
      this.$refs['modal-novo-item-peca'].abrirModal();
    },
    abreModalCriteriosAceitacao() {
      this.$refs['modal-criterios-aceitacao'].abrirModal();
    },
    abreModalEditarInstrumento() {
      this.$refs['modal-edicao-instrumento'].abrirModal();
    },
    buscaPrazoEntregaTipoPrazoAutomatico() {
      if (this.clienteParticipanteOrdemServicoId) {
        let params = {
          participanteId: this.clienteParticipanteOrdemServicoId,
          ordemServicoId: this.ordemServico,
        };
        AnaliseTecnicaService.buscaPrazoEntregaTipoPrazo(params)
          .then((res) => {
            if (res.data.prazoEntrega) {
              this.form.prazoEntregaEmDias = res.data.prazoEntrega;
            } else this.listarDadosPadrao();

            this.form.tipoPrazoEntrega = res.data.tipoPrazoEntrega;
          })
          .catch((err) => {
            this.toastErro(
              this.$t(`geral.erros.${err.response.data.errors[0]}`)
            );
          });
      }
    },
    listarDadosPadrao() {
      UtilsService.buscarDadosPadrao(this.id)
        .then((res) => {
          this.form.prazoEntregaEmDias = res.data.prazoEntregaPadrao;
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        });
    },
    servicosChecklist(servicosIds) {
      this.servicosIds = servicosIds;
    },
    pecasChecklist(pecasIds) {
      this.pecasIds = pecasIds;
    },
    atualizaInstrumentoAnaliseTecnica() {
      this.buscaDadosOrdemServico();
      this.atualizaListagemCalibracao++;
    },
    listarLocalExecucao() {
      UtilsService.buscaLocalExecucao()
        .then((res) => {
          res.data.forEach((el) => {
            if (el.instalacaoCliente) this.instalacoesClienteId.push(el.id);
          });
          this.opcoesLocalExecucao = new DropdownModel(res.data);
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        });
    },
    listarTipoCalibracao() {
      TipoCalibracaoService.listar()
        .then((res) => {
          this.opcoesTipoCalibracao = new DropdownModel(res.data);
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        });
    },
    mudaStep(step) {
      this.e1 = step;
    },
    validaFormularioDadosAnaliseTecnica() {
      if (this.$refs['dados-analise-tecnica'].$refs.form)
        this.valido = this.$refs['dados-analise-tecnica'].$refs.form.validate();
      if (this.valido) this.e1 = 3;
      else return;
    },
    abreModalRequisitos() {
      this.$refs['modal-requisito'].abrirModal();
    },
    buscaRequisitos(id) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      ParticipanteService.buscaRequisitosParticipante(id, 'tipoRequisito=2')
        .then((res) => {
          if (res?.data) {
            this.dadosRequisitosClienteFormatado = res?.data;
            this.dadosRequisitosClienteFormatado?.length &&
              this.abreModalRequisitos();
          }
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar() {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'operacao' });
      });
    },
    atualizaListagemPeca() {
      this.atualizaDadosAnaliseLoteInstrumentos++;
    },
  },
};
</script>
