<template lang="">
  <modal-padrao
    ref="modal-novo-item-peca"
    max-width="90%"
    :titulo="$t('modulos.operacao.formulario.novo_item_peca')"
    :titulo-ok="$t('geral.botoes.salvar')"
    :mostrar-botoes="false"
  >
    <formulario
      ref="formulario-novo-item-peca"
      :formulario-modal="formularioModal"
      :id-tipo-instrumento="idTipoInstrumento"
      :nome-tipo-instrumento="nomeTipoInstrumento"
      :id-modelo-instrumento="idModeloInstrumento"
      :nome-modelo-instrumento="nomeModeloInstrumento"
      :id-unidade-medida-instrumento="idUnidadeMedidaInstrumento"
      :nome-unidade-medida-instrumento="nomeUnidadeMedidaInstrumento"
      @fechaModalItem="fecharModal"
      @atualizaListagemPeca="atualizaListagemPeca"
    />
  </modal-padrao>
</template>
<script>
import formulario from '@views/modulos/cadastros/item/formulario';
export default {
  components: { formulario },
  props: {
    idTipoInstrumento: { type: String, default: '' },
    nomeTipoInstrumento: { type: String, default: '' },
    idModeloInstrumento: { type: String, default: '' },
    nomeModeloInstrumento: { type: String, default: '' },
    idUnidadeMedidaInstrumento: { type: String, default: '' },
    nomeUnidadeMedidaInstrumento: { type: String, default: '' },
  },
  data() {
    return {
      formularioModal: true,
    };
  },
  watch: {},
  mounted() {},
  methods: {
    abrirModal:async function() {
      this.$refs['modal-novo-item-peca'].abrirModal();
    },
    fecharModal() {
      this.$refs['modal-novo-item-peca'].fecharModal();
    },
    atualizaListagemPeca() {
      this.$emit('atualizaListagemPeca');
    },
  },
};
</script>
