<template>
  <div>
    <cabecalho-pagina
      :titulo="emModal ? '' : $t('modulos.criterio_aceitacao.titulos.listagem')"
      :total-registros="tabela.quantidadeItens"
      :titulo-botao-novo="$t('modulos.criterio_aceitacao.titulos.novo')"
      :sem-botao-ok="!buscarPermissao('CriterioAceitacao', 'Inserir')"
      :sem-filtro="!buscarPermissao('CriterioAceitacao', 'Listar')"
      @abrirNovo="abrirNovo"
      @filtrar="filtrar"
    >
      <template v-slot:filtro="{ filtro }">
        <filtro :filtro="filtro" />
      </template>
    </cabecalho-pagina>
    <botao-padrao
      v-if="podeExcluir && buscarPermissao('CriteiroAceitacao', 'Deletar')"
      class="my-2"
      outlined
      color="danger"
      :disabled="!$store.getters['Autenticacao/participanteLogadoConta']"
      @click="excluirRegistros"
    >
      <v-icon>$mdiTrashCanOutline</v-icon>
      {{ $t('geral.botoes.excluir') }}
    </botao-padrao>
    <tabela-padrao-prime-vue
      ref="tabela"
      v-model="tabela.selecionados"
      class="mt-2"
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      :mostrar-acoes="true"
      filtros-com-colunas
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :pagina="tabela.paginaAtual"
      :mostrar-seletor="buscarPermissao('CriteiroAceitacao', 'Deletar')"
      @paginar="listarRegistros"
      @filtrarGlobal="listarRegistros"
      @filtroGlobal="(v) => (filtroAplicado.filter = v)"
      @nova-ordenacao="ordenacaoColuna"
    >
      <template v-slot:acoes="{ slotProps }">
        <dropdown-padrao
          text
          color="secondary"
        >
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <dropdown-padrao-item
            v-if="buscarPermissao('CriterioAceitacao', 'Editar')"
            @click="abrirEditar(slotProps.data)"
          >
            {{ $t('geral.botoes.editar') }}
          </dropdown-padrao-item>
          <dropdown-padrao-item
            v-if="buscarPermissao('CriterioAceitacao', 'Visualizar')"
            @click="abrirVisualizar(slotProps.data)"
          >
            {{ $t('geral.botoes.visualizar') }}
          </dropdown-padrao-item>
        </dropdown-padrao>
      </template>
      <template v-slot:setores="{ slotProps }">
        <coluna-multiplos-itens :item="{ value: slotProps.data.setores }" />
      </template>
      <template v-slot:instrumentos="{ slotProps }">
        <coluna-multiplos-itens
          :item="{ value: slotProps.data.instrumentos }"
        />
      </template>
      <template v-slot:faixa="{ slotProps }">
        <div class="alinhar-direita">
          {{ slotProps.data.faixa | formatarFaixa }}
        </div>
      </template>
      <template v-slot:resolucao="{ slotProps }">
        <div class="alinhar-direita">
          {{ slotProps.data.resolucao | formatarNumeroComPrecisaoPodeVazio }}
        </div>
      </template>
    </tabela-padrao-prime-vue>
  </div>
</template>
<script>
import helpers from '@common/utils/helpers';
import CabecalhoPagina from '@components/layout/CabecalhoPagina.vue';
import CriterioAceitacaoService from '@common/services/cadastros/CriterioAceitacaoService.js';
import InstrumentoService from '@common/services/cadastros/InstrumentoService.js';
import Filtro from './filtro.vue';
import ColunaMultiplosItens from '@/components/tabelas/ColunaMultiplosItens.vue';
export default {
  components: {
    CabecalhoPagina,
    Filtro,
    ColunaMultiplosItens,
  },
  filters: {
    formatarFaixa: function (value) {
      if (value) {
        return value.replace(/\./g, ',');
      }
    },
  },
  props: {
    mostraCriteriosModalAnaliseTecnica: { type: Boolean, default: false },
    instrumentoId: { type: String, default: '' },
    atualizaListagem: { type: Number, default: 0 },
    emModal: { type: Boolean, default: false },
  },
  data() {
    return {
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'nome',
            text: this.$t('modulos.criterio_aceitacao.tabela.nome'),
            sortable: true,
          },
          {
            value: 'tipoInstrumento.nome',
            text: this.$t('modulos.criterio_aceitacao.tabela.tipo_instrumento'),
            sortable: false,
            width: 175,
          },
          {
            value: 'cliente.nome',
            text: this.$t('modulos.criterio_aceitacao.tabela.cliente'),
            sortable: false,
          },
          {
            value: 'setores',
            text: this.$t('modulos.criterio_aceitacao.tabela.setor'),
            sortable: false,
            width: 175,
          },
          {
            value: 'instrumentos',
            text: this.$t('modulos.criterio_aceitacao.tabela.instrumento'),
            sortable: false,
          },
          {
            value: 'tabela.nome',
            text: this.$t('modulos.criterio_aceitacao.tabela.tabela_registro'),
            sortable: false,
          },
          {
            value: 'faixa',
            text: this.$t('modulos.criterio_aceitacao.tabela.faixa'),
            sortable: false,
            width: 125,
          },
          {
            value: 'resolucao',
            text: this.$t('modulos.criterio_aceitacao.tabela.resolucao'),
            sortable: false,
          },
          {
            value: 'flagCriterioGenerico',
            text: this.$t(
              'modulos.criterio_aceitacao.tabela.criterio_generico'
            ),
            sortable: false,
            formatter: (v) => {
              return helpers.BoleanoEnum.find((el) => el.value === v)?.text;
            },
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 0,
        porPagina: 10,
      },
      filtroAplicado: { filter: '' },
      colunaOrdenada: '',
      filtroFormatado: null,
    };
  },
  computed: {
    podeExcluir: function () {
      return this.tabela.selecionados.length > 0;
    },
  },
  watch: {
    atualizaListagem() {
      this.listarRegistrosPagina();
    },
  },
  created() {
    helpers.redirecionarSemPermissao(this, 'CriterioAceitacao', 'Listar');
    const filtros = helpers.buscarStateFiltros(this);
    this.filtroAplicado = filtros?.filtroAplicado ?? { filter: '' };
    this.tabela.paginaAtual = filtros?.paginaAtual ?? 1;
    this.tabela.porPagina = filtros?.porPagina ?? 10;
  },
  mounted() {
    this.listarRegistrosPagina();
    if (!this.mostraCriteriosModalAnaliseTecnica) {
      this.$store.dispatch(
        'Layout/alterarTituloPagina',
        this.$t('modulos.criterio_aceitacao.titulos.listagem')
      );
    }
  },
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
    listarRegistrosPagina(
      paginaAtual = this.tabela.paginaAtual,
      porPagina = this.tabela.porPagina
    ) {
      if (!this.mostraCriteriosModalAnaliseTecnica) {
        this.listarRegistros(paginaAtual, porPagina);
      } else {
        this.listarRegistrosCriteriosAnaliseInstrumento();
      }
    },
    filtrar: function (filtro) {
      this.formatarValoresNumericos(filtro)
      
      this.filtroAplicado = { ...this.filtroFormatado, filter: this.filtroAplicado.filter };
      this.listarRegistrosPagina();
    },
    listarRegistros: function (
      paginaAtual = this.tabela.paginaAtual,
      porPagina = this.tabela.porPagina
    ) {
      this.tabela.porPagina = porPagina;
      helpers.salvarStateFiltros(this, {
        filtroAplicado: this.filtroAplicado,
        paginaAtual,
        porPagina,
      });
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        ...this.filtroAplicado,
        sort: this.colunaOrdenada,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      this.tabela.dados = [];
      CriterioAceitacaoService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items;
          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    listarRegistrosCriteriosAnaliseInstrumento: function () {
      if(!this.instrumentoId) return;
      this.$store.dispatch('Layout/iniciarCarregamento');
      InstrumentoService.buscarCriteriosAceitacaoInstrumento(this.instrumentoId)
        .then((res) => {
          this.tabela.dados = res.data;
          this.tabela.quantidadeItens = this.tabela.dados.length;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    excluirRegistros: function () {
      this.confirmarExcluir().then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');
        const listaIdExclusao = this.tabela.selecionados.map((s) => s.id);
        CriterioAceitacaoService.excluir(listaIdExclusao)
          .then(() => {
            this.listarRegistrosPagina();
            this.toastSucesso(
              this.$t(`modulos.criterio_aceitacao.exclusao_sucesso`)
            );
            this.tabela.selecionados = [];
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },

    abrirNovo: function () {
      if (!this.mostraCriteriosModalAnaliseTecnica) {
        this.$router.push({ name: 'criterio-aceitacao-novo' });
      } else {
        this.$emit('abrirNovoCriterio');
      }
    },
    abrirEditar: function (item) {
      if (!this.mostraCriteriosModalAnaliseTecnica) {
        this.$router.push({
          name: 'criterio-aceitacao-editar',
          params: { id: item.id },
        });
      } else {
        this.$emit('abrirNovoCriterio', item.id);
      }
    },
    abrirVisualizar: function (item) {
      this.$router.push({
        name: 'criterio-aceitacao-visualizar',
        params: { id: item.id },
      });
    },
    ordenacaoColuna(ordem) {
      this.colunaOrdenada = ordem;
      this.listarRegistros()
    },
    formatarValoresNumericos(filtro) {
       for (let key in filtro) {
        if (typeof filtro[key] === 'string' && filtro[key].includes(',')) {
            filtro[key] = filtro[key].replace(',', '.');
        }
    }
    return this.filtroFormatado = filtro;
    }
  },
};
</script>
<style scoped>
.alinhar-direita {
  display: flex;
  justify-content: right;
}
</style>
