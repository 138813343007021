<template>
  <div>
    <v-form
      ref="form"
      class="row"
    >
      <input-text
        v-model="form.codigo"
        class="col-12 col-md-2"
        :label="$t('modulos.item.formulario.codigo')"
        obrigatorio
        :max="50"
      />
      <input-text
        v-model="form.descricao"
        class="col-12 col-md-6"
        :label="$t('modulos.item.formulario.descricao')"
        trim
        obrigatorio
        :max="150"
      />
      <input-fabricante
        v-model="form.participanteFabricante"
        :multiplos="false"
        class="col-12 col-md-2"
        :label="$t('modulos.item.formulario.fabricante')"
      />
      <input-select
        v-model="form.categoriaItem"
        class="col-12 col-md-2"
        :label="$t('modulos.item.formulario.categoria')"
        :options="opcoes.categoria"
        :loading="loadingCategoria"
        retornar-objeto
        obrigatorio
      />

      <input-classificacao-fiscal
        v-model="form.classificacaoFiscal"
        class="col-12 col-md-3"
        :label="$t('modulos.item.formulario.classificacaoFiscal')"
        :multiplos="false"
      />
      <input-text
        v-model="form.cest"
        class="col-12 col-md-3"
        :label="$t('modulos.item.formulario.codigoEspecidicador')"
        :max="7"
      />
      <input-unidade-medida
        v-model="form.unidadeMedida"
        class="col-12 col-md-3"
        :label="$t('modulos.item.formulario.unidade_medida')"
        :multiplos="false"
      />
      <input-select
        v-model="form.tipoItem"
        class="col-12 col-md-3"
        :label="$t('modulos.item.formulario.tipo')"
        :options="opcoes.tipo"
        :loading="loadingTipo"
        retornar-objeto
        obrigatorio
      />
      <input-money
        v-model="form.valorCompra"
        class="col-12 col-md-2"
        :label="$t('modulos.item.formulario.valor_compra')"
        type="number"
        :options="optionsInputMoney"
      />
      <input-money
        v-model="form.valorFrete"
        class="col-12 col-md-2"
        :label="$t('modulos.item.formulario.valor_frete')"
        type="number"
        :options="optionsInputMoney"
      />
      <input-money
        v-model="form.valorVenda"
        class="col-12 col-md-2"
        :label="$t('modulos.item.formulario.valor_venda')"
        type="number"
        :options="optionsInputMoney"
      />
      <input-text
        v-model="form.prazoEntrega"
        class="col-12 col-md-2"
        :label="$t('modulos.item.formulario.prazo_entrega')"
        type="number"
      />
      <input-text
        v-model="form.codigoServicoLeiComplementar"
        class="col-12 col-md-2"
        :label="$t('modulos.item.formulario.codigo_servico')"
        :max="5"
      />
      <input-select
        v-model="form.alterarResultados"
        class="col-12 col-md-2"
        :label="$t('modulos.item.formulario.alterar_resultados')"
        :options="opcoes.alterarResultados"
        :loading="loadingAlterarResultados"
      />
      <input-text
        v-model="form.tempoExecucao"
        class="col-12 col-md-2"
        :label="$t('modulos.item.formulario.tempo_execucao')"
        type="number"
      />
      <input-text
        v-model="form.aliquotaIcms"
        class="col-12 col-md-2"
        :label="$t('modulos.item.formulario.alquota_ICMS')"
        type="number"
        :max="5"
      />
      <input-select
        v-model="form.localEstocagem"
        class="col-12 col-md-2"
        :label="$t('modulos.item.formulario.estoque_padrao')"
        :options="opcoes.estoque"
        :loading="loadingEstoque"
        retornar-objeto
      />
      <input-text
        v-model="form.descricaoCompleta"
        class="col-12 col-md-6"
        :label="$t('modulos.item.formulario.descricao_completa')"
        :max="2000"
      />
    </v-form>
  </div>
</template>
<script>
import { DropdownModel } from '@/common/models/geral/DropdownModel';
import { ItemModel } from '@common/models/cadastros/ItemModel';
import CategoriaItemService from '@common/services/cadastros/CategoriaItemService';
import ClassificacaoFiscalService from '@common/services/cadastros/ClassificacaoFiscalService';
import ItemService from '@common/services/cadastros/ItemService';
import LocalEstocagemService from '@common/services/cadastros/LocalEstocagemService';
import ParticipanteService from '@common/services/cadastros/ParticipanteService';
import TipoItemService from '@common/services/cadastros/TipoItemService';
import helpers from '@common/utils/helpers';
import { InputClassificacaoFiscal, InputFabricante, InputUnidadeMedida } from '@components/inputs';
export default {
  components: {
    InputUnidadeMedida,
    InputFabricante,
    InputClassificacaoFiscal
  },
  props: {
    form: {},
    formularioModal: { type: Boolean, default: false },
  },
  data() {
    return {
      valido: false,
      opcoes: {
        fabricante: [],
        categoria: [],
        classificacaoFiscal: [],
        estoque: [],
        tipo: [],
        alterarResultados: helpers.BoleanoEnum,
      },
      loadingCategoria: false,
      loadingClassificacaoFiscal: false,
      loadingEstoque: false,
      loadingTipo: false,
      loadingAlterarResultados: false,
      optionsInputMoney: {
        locale: 'pt-BR',
        prefix: '',
        suffix: '',
        length: 18,
        precision: 2,
      },
    };
  },
  computed: {
    tituloFormulario: function () {
      if (this.id) return this.$t('modulos.item.titulos.editar');
      return this.$t('modulos.item.titulos.novo');
    },
    tooltipBotaoSalvar: function () {
      if (this.valido) return '';
      return this.$t('modulos.item.validacoes.formulario_invalido');
    },
  },
  watch: {
    formularioModal() {
      this.inserirCategoriaPecaItemModal();
    },
    form: {
      handler() { },
      deep: true,
    },
  },
  mounted() {
    this.inserirCategoriaPecaItemModal();
    this.listarCategoria();
    this.listarClassificacaoFiscal();
    this.listarEstoque();
    this.listarTipo();
    if (this.id) this.buscar(this.id);
  },
  methods: {
    inserirCategoriaPecaItemModal() {
      if (this.formularioModal && this.opcoes.categoria.length) {
        this.opcoes.categoria.forEach((item) => {
          if (item.text == 'Peça' || item.text == 'Peças') {
            this.form.categoriaItem = item.value;
            this.$emit('atualizaCategoriaItem', item.value);
          }
        });
      }
    },
    buscar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      ItemService.buscar(this.id)
        .then((res) => {
          this.form = new ItemModel(res.data);
          this.valido = true;
        })
        .catch(() => {
          this.toastErro(this.$t('modulos.item.erros.id_invalido'));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    confirmacaoSalvar: function () {
      this.confirmarSalvar().then(() => {
        this.salvar();
      });
    },
    salvar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      ItemService.salvar(this.form)
        .then(() => {
          this.$router.push({ name: 'item' });
          this.toastSucesso(this.$t(`modulos.item.cadastro_sucesso`));
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'item' });
      });
    },
    listarfabricante: function () {
      ParticipanteService.listar({ papelFornecedor: 2 }).then((res) => {

        const fabricantes = res.data.items.filter(
          (item) => item.flagFabricante === true
        );
        this.opcoes.fabricante = new DropdownModel(fabricantes);
      });
    },
    listarCategoria: function () {
      this.loadingCategoria = true;
      CategoriaItemService.listar()
        .then((res) => {
          this.opcoes.categoria = new DropdownModel(res.data, 'descricao');
        })
        .finally(() => {
          this.loadingCategoria = false;
          this.inserirCategoriaPecaItemModal();
        });
    },
    listarClassificacaoFiscal: function () {
      this.loadingClassificacaoFiscal = true;
      ClassificacaoFiscalService.listar()
        .then((res) => {
          this.opcoes.classificacaoFiscal = new DropdownModel(
            res.data.items,
            'descricao'
          );
        })
        .finally(() => {
          this.loadingClassificacaoFiscal = false;
        });
    },
    listarEstoque: function () {
      this.loadingEstoque = true;
      LocalEstocagemService.listar()
        .then((res) => {
          this.opcoes.estoque = new DropdownModel(res.data);
        })
        .finally(() => {
          this.loadingEstoque = false;
        });
    },
    listarTipo: function () {
      this.loadingTipo = true;
      TipoItemService.listar()
        .then((res) => {
          this.opcoes.tipo = new DropdownModel(res.data, 'descricao');
        })
        .finally(() => {
          this.loadingTipo = false;
        });
    },
  },
};
</script>
