<template>
  <div class="row">
    <input-text
      v-model="filtro.nome"
      class="col-12 col-md-6"
      :label="$t('modulos.criterio_aceitacao.filtro.nome')"
      :placeholder="$t('modulos.criterio_aceitacao.filtro.nome')"
      em-filtro
    />
    <input-text
      v-model="filtro.tipoInstrumento"
      class="col-12 col-md-6"
      :label="$t('modulos.criterio_aceitacao.filtro.tipo_instrumento')"
      :placeholder="$t('modulos.criterio_aceitacao.filtro.tipo_instrumento')"
      em-filtro
    />
    <input-text
      v-model="filtro.cliente"
      class="col-12 col-md-6"
      :label="$t('modulos.criterio_aceitacao.filtro.cliente')"
      :placeholder="$t('modulos.criterio_aceitacao.filtro.cliente')"
      em-filtro
    />
    <input-text
      v-model="filtro.faixa"
      class="col-12 col-md-6"
      :label="$t('modulos.criterio_aceitacao.filtro.faixa')"
      :placeholder="$t('modulos.criterio_aceitacao.filtro.faixa')"
      em-filtro
    />
    <input-text
      v-model="filtro.resolucao"
      class="col-12 col-md-6"
      :label="$t('modulos.criterio_aceitacao.filtro.resolucao')"
      :placeholder="$t('modulos.criterio_aceitacao.filtro.resolucao')"
      em-filtro
    />
  </div>
</template>
<script>
export default {
  props: {
    filtro: {},
  },
};
</script>
