<template>
  <div>
    <div
      v-if="!formularioModal"
      class="d-flex mb-8"
    >
      <div class="titulo-pagina">
        {{ tituloFormulario }}
      </div>
    </div>
    <v-tabs
      v-model="indiceAba"
      class="abas-padrao mb-4"
      background-color="primary"
      color="primary"
    >
      <v-tab>
        {{ $t('modulos.item.formulario.abas.dados_item') }}
      </v-tab>
      <v-tab>
        {{ $t('modulos.item.formulario.abas.tipo_modelo_instrumento') }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="indiceAba">
      <v-tab-item>
        <dados-item
          ref="dados-item"
          :form="form"
          :formulario-modal="formularioModal"
          @atualizaCategoriaItem="atualizaCategoriaItem"
        />
      </v-tab-item>

      <v-tab-item>
        <tipo-modelo-instrumento
          ref="tipo-modelo-instrumento"
          :form="form"
        />
      </v-tab-item>
    </v-tabs-items>

    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end mt-4">
      <botao-padrao
        outlined
        color="secondary"
        class="mr-2"
        @click="cancelar"
      >
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao @click="confirmacaoSalvar">
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import { ItemModel } from '@common/models/cadastros/ItemModel';
import ItemService from '@common/services/cadastros/ItemService';
import helpers from '@common/utils/helpers';
import DadosItem from './components/DadosItem';
import TipoModeloInstrumento from './components/TipoModelo';

export default {
  components: {
    DadosItem,
    TipoModeloInstrumento,
  },
  props: [
    'id',
    'formularioModal',
    'idTipoInstrumento',
    'nomeTipoInstrumento',
    'idModeloInstrumento',
    'nomeModeloInstrumento',
    'idUnidadeMedidaInstrumento',
    'nomeUnidadeMedidaInstrumento',
  ],
  data() {
    return {
      indiceAba: 0,
      valido: false,
      form: new ItemModel({}),
      formEstadoInicialModal: new ItemModel({}),
    };
  },
  computed: {
    tituloFormulario: function () {
      if (this.id) return this.$t('modulos.item.titulos.editar');
      return this.$t('modulos.item.titulos.novo');
    },
  },
  watch: {
    idTipoInstrumento() {
      this.montaTipoModeloUnidadeInstrumentoModal();
    },
    idModeloInstrumento() {
      this.montaTipoModeloUnidadeInstrumentoModal();
    },
  },
  mounted() {
    this.montaTipoModeloUnidadeInstrumentoModal();
    if (this.id) {
      this.buscar(this.id)
      helpers.redirecionarSemPermissao(this, 'Item', 'Editar')
    }
    helpers.redirecionarSemPermissao(this, 'Item', 'Inserir');
    if (!this.formularioModal){
      this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
    }
  },
  methods: {
    montaTipoModeloUnidadeInstrumentoModal() {
      if (
        this.idUnidadeMedidaInstrumento &&
        this.nomeUnidadeMedidaInstrumento
      ) {
        this.form.unidadeMedida = {
          value: this.idUnidadeMedidaInstrumento,
          text: this.nomeUnidadeMedidaInstrumento,
        };
        this.form.unidadeMedidaId = this.idUnidadeMedidaInstrumento;
      }
      if (
        this.idTipoInstrumento &&
        this.nomeTipoInstrumento &&
        this.idModeloInstrumento &&
        this.nomeModeloInstrumento
      ) {
        this.form.itemTipoInstrumentoModeloInstrumento.push({
          modeloInstrumento: {
            id: this.idModeloInstrumento,
            nome: this.nomeModeloInstrumento,
          },
          modeloInstrumentoId: this.idModeloInstrumento,
          tipoInstrumento: {
            id: this.idTipoInstrumento,
            nome: this.nomeTipoInstrumento,
          },
          tipoInstrumentoId: this.idTipoInstrumento,
        });
      }

      this.formEstadoInicialModal = { ...this.form };
    },
    buscar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      ItemService.buscar(this.id)
        .then((res) => {
          this.form = new ItemModel(res.data);
          this.valido = true;
        })
        .catch(() => {
          this.toastErro(this.$t('modulos.item.erros.id_invalido'));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    confirmacaoSalvar: function () {
      if (!this.validarFormulario()) return;
      this.confirmarSalvar().then(() => {
        this.salvar();
      });
    },
    validarFormulario() {
      this.valido = this.$refs['dados-item'].$refs.form.validate();
      if (!this.valido) {
        this.indiceAba = 0;
        this.toastAlerta(
          this.$t('modulos.item.validacoes.formulario_invalido')
        );
        return false;
      }
      if (!this.form.itemTipoInstrumentoModeloInstrumento.length > 0) {
        this.indiceAba = 1;
        this.toastAlerta(
          this.$t('modulos.item.validacoes.inserir_instrumento')
        );
        return false;
      }
      return true;
    },
    salvar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');

      ItemService.salvar(this.form.request)
        .then(() => {
          if (this.formularioModal) {
            this.$emit('fechaModalItem');
            this.$emit('atualizaListagemPeca');
            this.resetaFormulario();
          } else this.$router.push({ name: 'item' });
          this.toastSucesso(this.$t(`modulos.item.cadastro_sucesso`));
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    resetaFormulario() {
      this.form = new ItemModel({});
      this.form.categoriaItem = this.formEstadoInicialModal.categoriaItem;
      this.form.itemTipoInstrumentoModeloInstrumento =
        this.formEstadoInicialModal.itemTipoInstrumentoModeloInstrumento;
      this.form.unidadeMedida = this.formEstadoInicialModal.unidadeMedida;
      this.form.unidadeMedidaId = this.formEstadoInicialModal.unidadeMedidaId;
    },
    atualizaCategoriaItem(categoriaItem) {
      this.formEstadoInicialModal.categoriaItem = categoriaItem;
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        if (this.formularioModal) {
          this.$emit('fechaModalItem');
          this.resetaFormulario();
        } else this.$router.push({ name: 'item' });
      });
    },
  },
};
</script>
