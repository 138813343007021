<template>
  <div>
    <v-form ref="form">
      <div class="mt-10">
        <data-table
          :value="form.dadosListagemChecklist"
          show-gridlines
          class="p-datatable-sm tabela-prime-vue-inherit"
          :rows="50"
          :scrollable="true"
          scroll-height="500px"
          data-key="id"
          :row-hover="true"
          :selection.sync="tabelaChecklist.selecionados"
          filter-display="menu"
          responsive-layout="scroll"
        >
          <Column field="check" header="" :styles="{ 'min-width': '215px' }">
            <template #body="slotProps">
              <input-radio
                :key="atualizaRadioCheck"
                v-model="slotProps.data.check"
                class="col-12 pt-0"
                :options="opcoes.booleano"
                @input="
                  alteraOpcaoOkNaoOk(
                    slotProps.data.check,
                    slotProps.index,
                    slotProps.data.sequencia
                  )
                "
              />
            </template>
          </Column>
          <Column
            field="descricao"
            :header="$t('modulos.operacao.tabela.descricao')"
          />
          <Column
            field="acaoNaoOk"
            :header="$t('modulos.operacao.tabela.acao_nao_ok')"
          />
          <Column
            field="servicos"
            :header="$t('modulos.operacao.tabela.servicos')"
          >
            <template #body="slotProps">
              <div>
                {{ slotProps.data.servicos.length }}
              </div>
            </template>
          </Column>
          <Column field="pecas" :header="$t('modulos.operacao.tabela.pecas')">
            <template #body="slotProps">
              <div>
                {{ slotProps.data.pecas.length }}
              </div>
            </template>
          </Column>
        </data-table>
      </div>
      <div class="d-flex mt-4" style="justify-content: space-between">
        <botao-padrao color="secondary" outlined @click="voltaStep()">
          <v-icon>$mdiArrowLeft</v-icon>
          {{ $t('modulos.contrato.formulario.itensContrato.steps.voltar') }}
        </botao-padrao>

        <botao-padrao color="primary" @click="proximoStep()">
          {{ $t('modulos.contrato.formulario.itensContrato.steps.proximo') }}
          <v-icon>$arrowRight</v-icon>
        </botao-padrao>
      </div>
    </v-form>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    form: { type: Object, default: () => ({}) },
    dadosChecklistSelecionado: { type: Array, default: () => [] },
    prazoGarantia: { type: Number, default: null },
    participanteId: { type: String, default: null },
    instrumentoId: { type: String, default: null },
    codigoInstrumento: { type: String, default: null },
    codigoOrdemServico: { type: String, default: null },
    nomeTipoInstrumento: { type: String, default: null },
    atualizaRadioCheck: { type: Number, default: 1 },
  },
  data() {
    return {
      keyAtualizaRadio: 1,
      opcoes: {
        booleano: [
          {
            text: 'Ok',
            value: true,
          },
          {
            text: 'Não Ok',
            value: false,
          },
        ],
      },
      editingRows: [],
      tabelaChecklist: {
        selecionados: [],
        dados: [],
        colunas: [],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
    };
  },
  watch: {},
  mounted() {},
  methods: {
    alteraOpcaoOkNaoOk(opcaoRadio, index) {
      if (this.form.dadosListagemChecklist.length) {
        this.form.analisesTecnicas.map((item) => {
          item.checklist = [];
        });
        this.form.dadosListagemChecklist.map((itemChecklist, indexDados) => {
          if (
            indexDados == index &&
            (itemChecklist.servicos.length || itemChecklist.pecas.length) &&
            opcaoRadio === false
          ) {
            this.confirmarAdicionarAutomaticamenteServicosPecas().then(() => {
              if (itemChecklist.servicos.length) {
                this.$emit('envia-servicos-checklist', itemChecklist.servicos);
              }
              if (itemChecklist.pecas.length) {
                this.$emit('envia-pecas-checklist', itemChecklist.pecas);
              }
            });
          }

          this.form.analisesTecnicas.map((item) => {
            if (itemChecklist.check !== undefined)
              item.checklist.push({
                id: itemChecklist.idEdicao,
                sequencia: itemChecklist.sequencia,
                descricao: itemChecklist.descricao,
                acaoNaoOk: itemChecklist.acaoNaoOk,
                flagOk: itemChecklist.check,
              });
          });
        });
      }
    },
    voltaStep() {
      this.$emit('volta-step', 2);
    },
    proximoStep() {
      this.$emit('proximo-step', 4);
    },
  },
};
</script>
<style scoped>
.tabela-padrao-com-acoes td:nth-child(2),
.tabela-padrao-com-acoes th:nth-child(2) {
  width: 0;
}
.tabela-padrao-com-seletor td:nth-child(1),
.tabela-padrao-com-seletor th:nth-child(1) {
  width: 0;
}
.tabela-prime-vue-inherit {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
}
</style>
