<template lang="">
  <modal-padrao
    ref="modal-criterios-aceitacao"
    max-width="95%"
    :titulo="$t('modulos.operacao.formulario.criterios_aceitacao')"
    :titulo-ok="$t('geral.botoes.salvar')"
    :titulo-cancelar="$t('geral.botoes.fechar')"
    :mostra-botao-salvar="false"
    :mostrar-botoes="!mostraFormulario"
  >
    <div v-if="codigoInstrumento">
      <v-chip
        class="mr-1"
        small
      >
        Código: {{ `${codigoInstrumento}` }}
      </v-chip>
    </div>
    <index
      v-if="mostraIndex"
      class="mt-4"
      em-modal
      :mostra-criterios-modal-analise-tecnica="mostraCriteriosModalAnaliseTecnica"
      :instrumento-id="instrumentoId || instrumento_id"
      :atualiza-listagem="atualizaListagem"
      @abrirNovoCriterio="abrirNovoCriterio"
    />
    <formulario
      v-if="mostraFormulario"
      :id="idCriterio"
      :instrumento-id="instrumentoId || instrumento_id"
      class="mt-4"
      :em-modal="true"
      :mostra-criterios-modal-analise-tecnica="mostraCriteriosModalAnaliseTecnica"
      @retornaParaIndexModal="mostraIndexOculaFormulario"
    />
  </modal-padrao>
</template>
<script>
import index from '@views/modulos/cadastros/criterio_aceitacao';
import formulario from '@views/modulos/cadastros/criterio_aceitacao/formulario';
export default {
  components: { index, formulario },
  props: {
    instrumentoId: { type: String, default: '' },
    codigoInstrumento: { type: String, default: null },
  },
  data() {
    return {
      mostraIndex: true,
      idCriterio: true,
      mostraFormulario: false,
      mostraCriteriosModalAnaliseTecnica: true,
      atualizaListagem: 1,
      instrumento_id: null,
    };
  },
  methods: {
    abrirNovoCriterio(id) {
      this.idCriterio = id ? id : null;
      this.mostraFormularioOculaIndex();
    },
    abrirModal(instrumentoId) {
      this.instrumento_id = instrumentoId;
      this.mostraIndexOculaFormulario();
      this.$refs['modal-criterios-aceitacao'].abrirModal();
    },
    fecharModal() {
      this.mostraIndexOculaFormulario();
      this.$refs['modal-criterios-aceitacao'].fecharModal();
    },
    mostraIndexOculaFormulario() {
      this.mostraIndex = true;
      this.mostraFormulario = false;
      this.atualizaListagem++;
    },
    mostraFormularioOculaIndex() {
      this.mostraIndex = false;
      this.mostraFormulario = true;
    },
  },
};
</script>
