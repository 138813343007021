<template>
  <div>
    <v-form ref="form">
      <div class="mt-10">
        Selecione as peças que deseja substituir no instrumento.
        <tabela-padrao-prime-vue
          :key="keyAtualiza"
          v-model="tabelaPecas.selecionados"
          :dados="form.dadosListagemPecas"
          filtro-geral
          class="mt-2"
          :colunas="tabelaPecas.colunas"
          paginacao-em-memoria
          sem-paginacao
          :filters="filters"
          ajustar-altura-linha
          ajustar-dropdown-acima
          :mostrar-acoes="false"
          :por-pagina="tabelaPecas.porPagina"
          :pagina-atual="tabelaPecas.paginaAtual"
          :global-filters="tabelaPecas.colunas.map(c => c.value)"
        >
          <template v-slot:quantidadeOrcada="{ slotProps }">
            <input-text 
              v-model="slotProps.data.quantidadeOrcada"
              sem-label
              flat
              solo
              type="number"
              apenas-numeros
              class="flex-fill input-tabela-analise-tecnica my-1"
              @input="atualizaQuantidadeOrcada"
            />
          </template>
        </tabela-padrao-prime-vue>
      </div>
      <div
        class="d-flex mt-4"
        style="justify-content: space-between"
      >
        <botao-padrao
          color="secondary"
          outlined
          @click="voltaStep()"
        >
          <v-icon>$mdiArrowLeft</v-icon>
          {{ $t('modulos.contrato.formulario.itensContrato.steps.voltar') }}
        </botao-padrao>

        <botao-padrao
          color="primary"
          @click="proximoStep()"
        >
          {{ $t('modulos.contrato.formulario.itensContrato.steps.proximo') }}
          <v-icon>$arrowRight</v-icon>
        </botao-padrao>
      </div>
    </v-form>
  </div>
</template>
<script>
import ItemService from '@common/services/cadastros/ItemService';
import OmieService from '@common/services/cadastros/OmieService';
import { FilterMatchMode } from 'primevue/api';
import helpers from '@common/utils/helpers';

// import _ from 'lodash';
export default {
  components: {},
  props: {
    form: { type: Object, default: () => ({}) },
    pecasIds: { type: Array, default: () => [] },
    dadosPecasSelecionadas: { type: Array, default: () => [] },
    prazoGarantia: { type: Number, default: null },
    participanteId: { type: String, default: null },
    instrumentoId: { type: String, default: null },
    codigoInstrumento: { type: String, default: null },
    codigoOrdemServico: { type: String, default: null },
    nomeTipoInstrumento: { type: String, default: null },
    idTipoInstrumento: { type: String, default: null },
    atualizaListagemPecas: { type: Number, default: 1 },
  },
  data() {
    return {
      validaQuantidadeOrcada: true,
      editingRows: [],
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      formInstrumento: null,
      precoServicoContrato: null,
      keyAtualiza: 0,
      codigoString: '',
      tabelaPecas: {
        selecionados: [],
        dados: [],
        colunas: [
          {
          value: 'instrumento.codigo',
          text: this.$t('modulos.operacao.tabela.instrumento'),
          },
          {
            value: 'codigo',
            text: this.$t('modulos.operacao.tabela.peca'),
            sorteable: true,
          },
          {
            value: 'descricao',
            text: this.$t('modulos.operacao.tabela.nome'),
            sorteable: true,
          },
          {
            value: 'participanteFabricante.nome',
            text: this.$t('modulos.operacao.tabela.fabricante'),
            sorteable: true,
          },
          {
            value: 'quantidadeEstoque',
            text: this.$t('modulos.operacao.tabela.quantidade_estoque'),
            sorteable: true,
          },
          {
            value: 'prazoEntrega',
            text: this.$t('modulos.operacao.tabela.prazo_entrega'),
            sorteable: true,
          },
          {
            value: 'tempoExecucao',
            text: this.$t('modulos.operacao.tabela.horas_execucao'),
            sorteable: true,
            formatter: v => v && helpers.formatarNumeroComPrecisao(v)
          },
          {
            value: 'quantidadeOrcada',
            text: this.$t('modulos.operacao.tabela.quantidade_orcada'),
            sorteable: true,
          },
          {
            value: 'ultimaExecucao',
            text: this.$t('modulos.operacao.tabela.ultima_execucao'),
            sorteable: true,
            formatter: v => v ? helpers.formatarDataBr(v) : '',
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
    };
  },
  watch: {
    pecasIds() {
      if (this.pecasIds.length > 0) {
        this.selecionaPecasChecklist(this.pecasIds);
      }
    },
    async 'tabelaPecas.selecionados'() {
      await this.atualizaDadosFormularioPecas();
    },
    atualizaListagemPecas() {
      this.listarPecas();
    },
    dadosPecasSelecionadas() {
      this.selecionaPecasDaEdicao();
    },
    'form.dadosListagemPecas'() {
      this.selecionaPecasDaEdicao();
    },
  },
  mounted() {},
  methods: {
    selecionaPecasDaEdicao() {
      if (this.form.dadosListagemPecas?.length) {
        this.tabelaPecas.selecionados = [];
        this.form.dadosListagemPecas.forEach((item) => {
          this.dadosPecasSelecionadas.forEach((itemSelecionado) => {
            if (
              item.pecaId == itemSelecionado.pecaId &&
              item.instrumento.id == itemSelecionado.instrumentoId
            ) {
              item.quantidadeOrcada = itemSelecionado.quantidadeOrcada;
              if (!this.tabelaPecas.selecionados.includes(item))
                this.tabelaPecas.selecionados.push(item);
            }
          });
        });
      }
    },
    selecionaPecasChecklist(pecasIds) {
      if (this.form.dadosListagemPecas?.length) {
        this.form.dadosListagemPecas.forEach((itemPeca) => {
          pecasIds.forEach((pecaId) => {
            if (itemPeca.id == pecaId) {
              if (!this.tabelaPecas.selecionados.includes(itemPeca))
                this.tabelaPecas.selecionados.push(itemPeca);
            }
          });
        });
      }
    },
    atualizaDadosFormularioPecas() {
      this.form.dadosPecasResumo = [];
      this.form.analisesTecnicas.map((item) => {
        item.pecas = [];
      });
      if (this.tabelaPecas.selecionados.length) {
        this.form.dadosPecasResumo = this.tabelaPecas.selecionados;
        this.tabelaPecas.selecionados.map((itemPeca) => {
          this.form.analisesTecnicas.map((item) => {
            if (itemPeca.instrumento.id == item.instrumentoId) {
              item.pecas.push({
                pecaId: itemPeca.pecaId,
                valor: itemPeca.valorVenda,
                quantidadeOrcada: itemPeca.quantidadeOrcada,
                horasExecucao: itemPeca.tempoExecucao,
                ultimaExecucao: itemPeca.ultimaExecucao,
              });
            }
          });
        });
      }
    },
    atualizaQuantidadeOrcada(){
      this.atualizaDadosFormularioPecas();
    },
    onRowEditSave(event) {
      let { newData, index } = event;
      this.form.dadosListagemPecas[index] = newData;
    },
    voltaStep() {
      this.$emit('volta-step', 4);
    },
    voltaStepValidacao: async function(){
      await this.$nextTick();
      this.$emit('proximo-step', 5);
    },
    proximoStep(mudaStep = true) {
      const valido = this.tabelaPecas.selecionados.every(el => !!parseFloat(el.quantidadeOrcada));
      if (!valido) {
        this.voltaStepValidacao();
        return this.toastAlerta(this.$t(`modulos.operacao.erros.quantidade_orcada_nao_pode_zero`))
      }
      if(mudaStep) this.$emit('proximo-step', 6);
    },
    listarPecas() {
      this.$store.dispatch('Layout/iniciarCarregamento');
      let params = {
        flagEhPeca: true,
        instrumentosId: this.form.arrayInstrumentosIds ?? null,
      };
      ItemService.listar(params)
        .then((res) => {
          this.form.dadosListagemPecas = res.data.items;
          this.form.dadosListagemPecas.map((item) => {
            item.quantidadeOrcada = 1;
          });
          this.tabelaPecas.quantidadeItens = res.data.totalItems;
          this.tabelaPecas.quantidadePaginas = res.data.totalPages;
          this.tabelaPecas.paginaAtual = 1;
          this.tabelaPecas.porPagina = 10;

          this.codigoString = '';
          if (res.data.items.length) {
            res.data.items.map((item) => {
              this.codigoString += `&Codigos=${item.codigo}`;
            });
          }

          this.montaListagemPecas(this.codigoString);
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          if (this.dadosPecasSelecionadas?.length)
            this.selecionaPecasDaEdicao();
        });
    },
    montaListagemPecas(codigoString) {
      if (codigoString) {
        let parametros = {
          flagFiltrarCodigos: true,
        };
        this.$store.dispatch('Layout/iniciarCarregamento');
        OmieService.buscaDadosOmie(codigoString, parametros)
          .then((res) => {
            this.form.dadosListagemPecas.map((item) => {
              if (res.data.produtos.length) {
                res.data.produtos.map((itemProduto) => {
                  if (item.codigo === itemProduto.codigo)
                    item.quantidadeEstoque = itemProduto.saldo;
                });
              }
            });
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      }
    },
  },
};
</script>

<style scoped>
.tabela-padrao-com-acoes td:nth-child(2),
.tabela-padrao-com-acoes th:nth-child(2) {
  width: 0;
}
.tabela-padrao-com-seletor td:nth-child(1),
.tabela-padrao-com-seletor th:nth-child(1) {
  width: 0;
}
.tabela-prime-vue-inherit {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
}
</style>
