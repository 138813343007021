<template>
  <div class="geralas">
    <div class="d-flex w-100 flex-column mb-4">
      <div class="d-flex w-100 justify-space-between">
        <cabecalho-pagina
          :titulo="
            abertoComoModal
              ? ''
              : `${
                $t('modulos.instrumento.titulos.instrumento') +
                ' - ' +
                instrumento.codigoTag
              }`
          "
          :total-registros="tabela.quantidadeItens"
          sem-filtros
          sem-botoes
        />
        <input-select
          v-if="!abertoComoModal"
          v-model="faixaSelecionadaId"
          :label="$t('modulos.modelo_instrumento.tabela.faixas')"
          :options="opcoes.faixas"
          class="mb-2"
          em-filtro
        />
      </div>
      <div class="d-flex flex-row justify-space-between">
        <div>
          <botao-padrao
            v-if="podeExcluir"
            class="my-2"
            outlined
            color="danger"
            @click="excluirRegistros"
          >
            <v-icon>$mdiTrashCanOutline</v-icon>
            {{ $t('geral.botoes.excluir') }}
          </botao-padrao>
        </div>
        <div class="d-flex align-center flex-row justify-space-between w-auto mt-2">
          <input-select
            v-if="abertoComoModal"
            v-model="faixaSelecionadaId"
            :label="'Faixa'"
            label-dentro
            class="mr-2"
            :options="opcoes.faixas"
          />
          <botao-padrao
            v-if="!tipoInstrumento.flagPontosSomenteNoTipo"
            class="mr-3"
            @click="abrirNovo"
          >
            {{ $t('modulos.instrumento.botoes.novo_ponto') }}
          </botao-padrao>
          <botao-padrao
            :disabled="!!tabela.dados?.length"
            class="mr-3"
            @click="usarPontosModelo"
          >
            {{ $t('modulos.instrumento.botoes.usar_ponto_modelo') }}
          </botao-padrao>
          <botao-padrao
            class="mx-0"
            @click="associarPontos"
          >
            {{ $t('modulos.instrumento.botoes.selecionar_pontos') }}
          </botao-padrao>
        </div>
      </div>
    </div>
    <tabela-padrao-prime-vue
      v-model="tabela.selecionados"
      :dados="tabela.dados"
      class="mt-2"
      :colunas="tabela.colunas"
      :quantidade-itens="tabela.quantidadeItens"
      :quantidade-paginas="tabela.quantidadePaginas"
      paginacao-em-memoria
      sem-paginacao
      ajustar-dropdown-acima
      :pagina-atual="tabela.paginaAtual"
      sort-field="valor"
      :sort-order="1"
    >
      <!-- eslint-disable vue/valid-v-slot-->
      <template v-slot:acoes="{ slotProps }">
        <dropdown-padrao
          text
          color="secondary"
        >
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <dropdown-padrao-item
            v-if="!tipoInstrumento.flagPontosSomenteNoTipo"
            @click="abrirEditar(slotProps.data)"
          >
            {{ $t('geral.botoes.editar') }}
          </dropdown-padrao-item>
        </dropdown-padrao>
      </template>
    </tabela-padrao-prime-vue>
    <template>
      <v-divider class="my-5" />
      <div class="d-flex align-center justify-end">
        <botao-padrao
          outlined
          color="secondary"
          class="mr-2"
          @click="cancelar"
        >
          <v-icon>$mdiCancel</v-icon>
          {{ $t('geral.botoes.cancelar') }}
        </botao-padrao>
        <botao-padrao @click="salvar">
          <v-icon>$mdiContentSaveOutline</v-icon>
          {{ $t('geral.botoes.salvar') }}
        </botao-padrao>
      </div>
    </template>
    <PontosForm
      ref="modal-pontos"
      @atualizarTabela="atualizarTabela"
    />
    <AssociarPontos
      ref="modal-associar-pontos"
      @atualizarTabela="atualizarTabela"
    />
  </div>
</template>
<script>
import CabecalhoPagina from '@components/layout/CabecalhoPagina.vue';
import PontosForm from './components/modais/PontosForm.vue';
import ModeloInstrumentoService from '@common/services/cadastros/ModeloInstrumentoService.js';
import InstrumentoService from '@common/services/cadastros/InstrumentoService.js';
import AssociarPontos from './components/modais/AssociarPontos.vue';
import { InstrumentoModel } from '@common/models/cadastros/InstrumentoModel';
import { DropdownFaixas } from '@common/models/geral/DropdownFaixas';
import helpers from '@common/utils/helpers.js';

export default {
  components: {
    CabecalhoPagina,
    PontosForm,
    AssociarPontos,
  },
  props: {
    abertoComoModal: { type: Boolean, default: false },
    instrumentoId: { type: String, default: '' },
    faixaId: { type: String, default: null },
  },
  data() {
    return {
      instrumento: {},
      faixaSelecionadaId: null,
      faixaSelecionada: {},
      tipoInstrumento: {},
      podeSalvar: true,
      pontosRemovidos: [],
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'valor',
            text: this.$t('modulos.tipo_instrumento.tabela.pontos.valor'),
            sortable: true,
            formatter: (v) => helpers.formatarNumeroComPrecisao(v),
          },
          {
            value: 'descricao',
            text: this.$t('modulos.tipo_instrumento.tabela.pontos.descricao'),
            sortable: true,
          },
          {
            value: 'tipoPonto',
            text: this.$t('modulos.tipo_instrumento.tabela.pontos.tipo_ponto'),
            sortable: true,
            formatter: (v) => {
              return helpers.TipoPontoEnum.find((el) => el.value == v).text;
            },
          },
          {
            value: 'origemPonto',
            text: this.$t(
              'modulos.tipo_instrumento.tabela.pontos.origem_ponto'
            ),
            sortable: true,
            formatter: (v) => {
              return helpers.OrigemPontoEnum.find((el) => el.value == v).text;
            },
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      filtroAplicado: { filter: '' },
      opcoes: {
        faixas: [],
      },
    };
  },
  computed: {
    podeExcluir: function () {
      return this.tabela.selecionados != 0;
    },
  },
  watch: {
    faixaSelecionadaId(id) {
      if (!id) return;
      this.buscarFaixaSelecionada(id);
    },
  },
  mounted: async function () {
    if (!this.instrumentoId) {
      this.$store.dispatch(
        'Layout/alterarTituloPagina',
        this.$t('modulos.tipo_instrumento.titulos.guia_navegador')
      );
    }
    await this.buscar();
  },
  methods: {
    buscar: async function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      let id;
      if (this.instrumentoId) id = this.instrumentoId;
      else id = this.$route.params.id;
      await InstrumentoService.buscar(id)
        .then((res) => {
          this.instrumento = new InstrumentoModel(res.data);
          this.opcoes.faixas = new DropdownFaixas(
            res.data.instrumentoFaixa,
            'descricao'
          );
          ModeloInstrumentoService.buscar(
            this.instrumento.modeloInstrumento?.value
          ).then((modelo) => {
            this.tipoInstrumento = modelo.data.tipoInstrumento;
            this.instrumento.nome = modelo.data.nome ? modelo.data.nome : '';
            this.$forceUpdate();
          });

          this.faixaSelecionadaId = this.faixaId ?? this.opcoes.faixas[0]?.value;
        })
        .catch(() => {
          // this.mostrarIdInvalido();
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    excluirRegistros: function () {
      this.confirmarExcluir().then(() => {
        this.tabela.selecionados.forEach((element) => {
          this.pontosRemovidos.push(element);
          this.faixaSelecionada.pontosCalibracao.splice(
            this.faixaSelecionada.pontosCalibracao.indexOf(element),
            1
          );
        });
        this.tabela.selecionados = [];
        this.atualizarTabela();
      });
    },
    associarPontos() {
      if (!this.faixaSelecionadaId) {
        this.toastAlerta(
          this.$t('modulos.tipo_instrumento.erros.selecione_faixa')
        );
        return;
      }
      this.$refs['modal-associar-pontos'].abrirModal(
        this.instrumento.modeloInstrumento?.value,
        this.faixaSelecionada,
        this.tipoInstrumento.flagPontosDuplicados
      );
    },
    abrirNovo: function () {
      if (!this.faixaSelecionadaId) {
        this.toastAlerta(
          this.$t('modulos.tipo_instrumento.erros.selecione_faixa')
        );
        return;
      }
      this.$refs['modal-pontos'].abrirModal(
        this.instrumento.nome,
        this.faixaSelecionada,
        null,
        this.tipoInstrumento.flagPontosDuplicados
      );
    },
    abrirEditar: function (pontoCalibracao) {
      this.$refs['modal-pontos'].abrirModal(
        this.instrumento.nome,
        this.faixaSelecionada,
        pontoCalibracao,
        this.tipoInstrumento.flagPontosDuplicados
      );
    },
    buscarFaixaSelecionada() {
      if (!this.faixaSelecionadaId) return;
      this.faixaSelecionada = this.instrumento.instrumentoFaixa.find(
        (el) => el.id === this.faixaSelecionadaId
      );
      this.buscarPontos(this.faixaSelecionada);
    },
    atualizarTabela() {
      this.tabela.quantidadeItens = this.tabela.dados?.length;
      this.tabela.quantidadePaginas =
        Math.ceil(this.tabela.quantidadeItens / this.tabela.porPagina) || 0;
    },
    buscarPontos(faixa) {
      if (!faixa.pontosCalibracao) faixa.pontosCalibracao = [];
      return InstrumentoService.buscarPontos(this.instrumento.id, faixa.id)
        .then((res) => {
          res.data?.forEach((ponto) => {
            ponto.flagJaExistente = true;
            if (!faixa.pontosCalibracao.some((el) => el.id == ponto.id) && !this.pontosRemovidos.some(el => el.id == ponto.id))
              faixa.pontosCalibracao?.push(ponto);
          });
        })
        .finally(() => {
          this.tabela.dados = this.faixaSelecionada.pontosCalibracao;
          this.tabela.quantidadeItens = this.tabela.dados?.length;
          this.tabela.quantidadePaginas =
            Math.ceil(this.tabela.quantidadeItens / this.tabela.porPagina) || 0;
        });
    },
    cancelar() {
      if (!this.abertoComoModal) {
        this.$router.push({
          name: this.$route.query?.retorno || 'instrumento',
        });
      } else {
        this.$emit('fecha-modal');
      }
    },
    salvar: async function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      const promises = [];
      this.instrumento.instrumentoFaixa.forEach((faixa) => {
        if (!faixa .pontosCalibracao) return;
        faixa.pontosCalibracao?.forEach((ponto) => {
          if (!ponto.flagJaExistente) ponto.id = null;
        });
        promises.push(
          InstrumentoService.salvarPontos(this.instrumento.id, faixa.id, {
            pontos: faixa.pontosCalibracao,
          })
        );
      });
      await Promise.all(promises)
        .then(() => {
          this.toastSucesso(this.$t(`modulos.instrumento.edicao_sucesso`));
          this.cancelar();
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    usarPontosModelo() {
      this.$store.dispatch('Layout/iniciarCarregamento');
      ModeloInstrumentoService.buscar(this.instrumento.modeloInstrumento?.value)
        .then((res) => {
          this.tabela.selecionados = [];
          const pontos = res.data.faixas
            .find(el => el.descricao == this.faixaSelecionada.descricao && el.resolucao == this.faixaSelecionada.resolucao)
            ?.modeloInstrumentoFaixaPonto;
          pontos.forEach(ponto => {
            this.faixaSelecionada.pontosCalibracao.push(ponto);
          });
          this.tabela.quantidadeItens = this.tabela.dados?.length;
          this.tabela.quantidadePaginas = Math.ceil(this.tabela.quantidadeItens / this.tabela.porPagina) || 0;
        }).
        finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    }
  },
};
</script>
