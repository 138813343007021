<template lang="">
  <modal-padrao
    ref="modal-novo-ponto-calibracao"
    max-width="90%"
    :titulo="
      $t('modulos.instrumento.tabela.pontos_de_calibracao', {
        codigoTag: codigoInstrumento,
        nomeTipoInstrumento: nomeTipoInstrumento,
      })
    "
    :titulo-ok="$t('geral.botoes.salvar')"
    :mostrar-botoes="false"
    @modal-fechado="modalAberto = false"
  >
    <div v-if="codigoInstrumento">
      <v-chip
        class="mr-1"
        small
      >
        Código: {{ `${codigoInstrumento}` }}
      </v-chip>
    </div>
    <pontos-calibracoes
      v-if="modalAberto"
      :aberto-como-modal="true"
      :instrumento-id="instrumentoId"
      @fecha-modal="fecharModal"
    />
  </modal-padrao>
</template>
<script>
import PontosCalibracoes from '@views/modulos/cadastros/instrumento/pontosCalibracoes';
export default {
  components: { PontosCalibracoes },
  props: {
    instrumentoId: { type: String, default: '' },
    codigoInstrumento: { type: String, default: null },
    nomeTipoInstrumento: { type: String, default: null },
  },
  data() {
    return {
      indiceAba: 0,
      modalAberto: false
    };
  },
  methods: {
    abrirModal: async function() {
      this.modalAberto = true;
      await this.$nextTick();
      this.$refs['modal-novo-ponto-calibracao'].abrirModal();
    },
    fecharModal() {
      this.$emit('atualizarInstrumento', this.instrumentoId);
      this.$refs['modal-novo-ponto-calibracao'].fecharModal();
    },
  },
};
</script>
