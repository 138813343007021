<template lang="">
  <modal-padrao
    id="modal-pontos"
    ref="modal-pontos"
    :max-width="$vuetify.breakpoint.name == 'xs' ? '90%' : '60%'"
    :titulo="$t('modulos.tipo_instrumento.botoes.ponto_calibracao')"
    :subtitulo="subtitulo"
    :titulo-ok="`${$t('geral.botoes.salvar_sair')} (F2)`"
    :titulo-cancelar="`${$t('geral.botoes.fechar')} (ESC)`"
    @ok="salvar_sair"
    @keydown="eventoUsabilidade"
  >
    <v-form ref="form" class="row">
      <input-text
        ref="input-valor"
        v-model="form.valor"
        class="col-12 col-md-6"
        :label="$t('modulos.tipo_instrumento.formulario.pontos.valor')"
        obrigatorio
        :regras-personalizadas="[validacaoPersonalizadaValor]"
        type="number"
      />
      <input-select
        v-model="form.tipoPonto"
        class="col-12 col-md-6"
        :label="$t('modulos.tipo_instrumento.formulario.pontos.tipo_ponto')"
        obrigatorio
        :options="opcoes.tipoPonto"
      />
      <input-textarea
        v-model="form.descricao"
        class="col-12 col-md-12"
        :label="$t('modulos.tipo_instrumento.formulario.pontos.descricao')"
      />
    </v-form>
    <template v-slot:botaoAdicional>
      <botao-padrao
        color="primary"
        :class="{ 'ml-2': !EhTelaPequena }"
        :width="EhTelaPequena ? 240 : 'auto'"
        @click="salvar_continuar"
      >
        {{ `${$t('geral.botoes.salvar_continuar')} (F3)` }}
      </botao-padrao>
    </template>
  </modal-padrao>
</template>
<script>
import helpers from '@common/utils/helpers.js';
export default {
  data() {
    return {
      form: {},
      faixaReferencia: {},
      pontoReferencia: {},
      flagPontosDuplicados: null,
      subtitulo: '',
      opcoes: {
        tipoPonto: helpers.TipoPontoEnum,
      },
      edicao: false,
    };
  },
  computed: {
    EhTelaPequena() {
      return ['sm', 'xs'].includes(this.$vuetify.breakpoint.name);
    },
  },
  methods: {
    abrirModal: function (
      tipoInstrumentoNome,
      faixaSelecionada,
      pontoCalibracao,
      flagPontosDuplicados
    ) {
      this.flagPontosDuplicados = flagPontosDuplicados;
      this.subtitulo = `${tipoInstrumentoNome} - (${faixaSelecionada.descricao})`;
      this.faixaReferencia = faixaSelecionada;
      if (pontoCalibracao) {
        this.form = { ...pontoCalibracao };
        this.pontoReferencia = pontoCalibracao;
        this.edicao = true;
      } else {
        this.edicao = false;
        this.iniciarForm();
      }
      this.$refs['modal-pontos'].abrirModal();
    },
    iniciarForm() {
      this.form = {
        id: helpers.gerarNumeroAleatorio(),
        valor: null,
        descricao: null,
        tipoPonto: this.form.tipoPonto,
        origemPonto: null,
      };
      this.$refs.form?.resetValidation();
    },
    verificarExistente(valorPonto){
      return this.faixaReferencia.pontosCalibracao
        .some(ponto => ponto.valor == valorPonto &&
           this.form.id != ponto.id && ponto.tipoPonto == this.form.tipoPonto)
    },
    validacaoPersonalizadaValor(v) {
      if (this.flagPontosDuplicados == false) {
        if(this.verificarExistente(v))
        return this.$t('modulos.tipo_instrumento.validacoes.ponto_existente')
      }

      if(this.form.tipoPonto === 'Percentual'){
          if (v < 0 || v > 100)
            return this.$t(
              'modulos.tipo_instrumento.validacoes.valor_percentual'
            );
          return true;
        }
      if (this.form.tipoPonto == 'ValorFixo') {
        if (
          v < this.faixaReferencia.amplitudeMinima ||
          v > this.faixaReferencia.amplitudeMaxima
        )
          return this.$t('modulos.instrumento.validacoes.valor_fora_faixa');
      }
      return true;
    },
    eventoUsabilidade(event) {
      switch (event.key) {
        case 'F3':
          event.preventDefault();
          this.salvar_continuar();
          break;
        case 'F2':
          event.preventDefault();
          this.salvar_sair();
          break;
      }
    },
    salvar_sair() {
      if (!this.$refs.form?.validate()) return;
      this.salvar_continuar();
      this.$refs['modal-pontos'].fecharModal();
    },
    salvar_continuar() {
      if (!this.$refs.form?.validate()) return;
      this.form.origemPonto = helpers.OrigemPontoEnum.find(
        (el) => el.text == 'Instrumento'
      ).value;
      if (!this.edicao)
        this.faixaReferencia.pontosCalibracao.push({ ...this.form });
      if (this.edicao) {
        Object.entries(this.form).forEach((value) => {
          this.pontoReferencia[value[0]] = value[1];
        });
      }
      this.iniciarForm();
      this.$refs['input-valor']?.focus();
      this.$emit('atualizarTabela');
    },
  },
};
</script>
