<template>
  <div>
    <v-form ref="form">
      <div class="row">
        <input-select
          v-model="form.localExecucaoId"
          class="col-12 col-md-3"
          :label="$t('modulos.operacao.formulario.local_execucao')"
          :options="opcoes.localExecucao"
          disabled
        />
        <input-select
          v-model="form.tipoPrazoEntrega"
          class="col-12 col-md-3"
          :label="$t('modulos.operacao.formulario.tipo_prazo_entrega')"
          :options="opcoes.tipoPrazoEntrega"
          disabled
        />
        <input-text
          v-model="form.prazoEntregaEmDias"
          class="col-12 col-md-3"
          :label="$t('modulos.operacao.formulario.prazo_entrega')"
          type="number"
          disabled
        />
      </div>
      <accordion-padrao
        class="col-12"
        cor-azul-texto
        alterar-cor
        :titulo="$t('modulos.operacao.steps.calibracao')"
      >
        <div
          v-if="form.dadosCalibracaoResumo?.length"
          class="row"
        >
          <input-select
            v-model="tipoCalibracaoId"
            class="col-md-4"
            :label="$t('modulos.operacao.formulario.tipo_calibracao')"
            :options="opcoes.tipoCalibracaoEnum"
            disabled
          />
        </div>
        <tabela-padrao-prime-vue
          v-if="form.dadosCalibracaoResumo?.length"
          :key="keyAtualiza"
          :dados="form.dadosCalibracaoResumo"
          data-key="sequencia"
          class="mt-2"
          :colunas="tabelaCalibracao.colunas"
          sem-paginacao
          :mostrar-seletor="false"
          :mostra-header="false"
          :paginacao-em-memoria="form.dadosCalibracaoResumo?.length && form.dadosCalibracaoResumo?.length > 5"
          ajustar-altura-linha
          :mostrar-acoes="false"
          :por-pagina="tabelaCalibracao.porPagina"
          :pagina-atual="tabelaCalibracao.paginaAtual"
          filtro-geral
          :filters="filters"
          :global-filters="tabelaCalibracao.colunas.map(c => c.value)"
        >
          <template v-slot:faixaInstrumento="{ slotProps }">
            <span> {{ montarDescricaoFaixa(slotProps.data.instrumento?.codigo) }}</span>
          </template>
          >
          <template v-slot:faixa="{ slotProps }">
            <div
              v-if="slotProps.data.amplitudeMinima || slotProps.data?.amplitudeMaxima"
              class="alinhar-direita"
            >
              {{
                `(${
                  slotProps.data?.amplitudeMinima ? slotProps.data.amplitudeMinima + ' a ' : ''
                }${
                  slotProps.data?.amplitudeMaxima || '...'
                }) ${
                  slotProps.data?.unidadeMedida?.simbolo || ''
                }`
              }}
            </div>
          </template>
        </tabela-padrao-prime-vue>
      </accordion-padrao>
      <accordion-padrao
        class="col-12"
        cor-azul-texto
        alterar-cor
        :titulo="$t('modulos.operacao.steps.manutencao')"
      >
        <tabela-padrao-prime-vue
          v-if="form.dadosManutencaoResumo?.length"
          :key="keyAtualiza"
          :dados="form.dadosManutencaoResumo"
          data-key="sequencia"
          class="mt-2"
          :colunas="tabelaManutencao.colunas"
          sem-paginacao
          :mostrar-seletor="false"
          :mostra-header="false"
          :paginacao-em-memoria="form.dadosManutencaoResumo?.length && form.dadosManutencaoResumo?.length > 5"
          ajustar-altura-linha
          :mostrar-acoes="false"
          :por-pagina="tabelaManutencao.porPagina"
          :pagina-atual="tabelaManutencao.paginaAtual"
          filtro-geral
          :filters="filters"
          :global-filters="tabelaManutencao.colunas.map(c => c.value)"
        >
          <template v-slot:faixaInstrumento="{ slotProps }">
            <span> {{ montarDescricaoFaixa(slotProps.data.instrumento?.codigo) }}</span>
          </template>
          >
          <template v-slot:faixa="{ slotProps }">
            <div
              v-if="slotProps.data.amplitudeMinima || slotProps.data?.amplitudeMaxima"
              class="alinhar-direita"
            >
              {{
                `(${
                  slotProps.data?.amplitudeMinima ? slotProps.data.amplitudeMinima + ' a ' : ''
                }${
                  slotProps.data?.amplitudeMaxima || '...'
                }) ${
                  slotProps.data?.unidadeMedida?.simbolo || ''
                }`
              }}
            </div>
          </template>
        </tabela-padrao-prime-vue>
      </accordion-padrao>
      <accordion-padrao
        class="col-12"
        cor-azul-texto
        alterar-cor
        :titulo="$t('modulos.operacao.steps.pecas')"
      >
        <tabela-padrao-prime-vue
          v-if="form.dadosPecasResumo?.length"
          :key="keyAtualiza"
          :dados="form.dadosPecasResumo"
          data-key="sequencia"
          class="mt-2"
          :colunas="tabelaPecas.colunas"
          sem-paginacao
          :mostrar-seletor="false"
          :mostra-header="false"
          :paginacao-em-memoria="form.dadosPecasResumo?.length && form.dadosPecasResumo?.length > 5"
          ajustar-altura-linha
          :mostrar-acoes="false"
          :por-pagina="tabelaPecas.porPagina"
          :pagina-atual="tabelaPecas.paginaAtual"
          filtro-geral
          :filters="filters"
          :global-filters="tabelaPecas.colunas.map(c => c.value)"
        />
      </accordion-padrao>
      <div
        class="d-flex mt-4"
        style="justify-content: start"
      >
        <botao-padrao
          color="secondary"
          outlined
          @click="voltaStep()"
        >
          <v-icon>$mdiArrowLeft</v-icon>
          {{ $t('modulos.contrato.formulario.itensContrato.steps.voltar') }}
        </botao-padrao>
      </div>
    </v-form>
  </div>
</template>
<script>
import { FilterMatchMode } from 'primevue/api';
import helpers from '@common/utils/helpers';

export default {
  components: {},
  props: {
    form: { type: Object, default: () => ({}) },
    opcoesLocalExecucao: { type: Array, default: () => [] },
    opcoesTipoCalibracao: { type: Array, default: () => [] },
    codigoInstrumento: { type: String, default: null },
    codigoOrdemServico: { type: String, default: null },
    nomeTipoInstrumento: { type: String, default: null },
    atualizaListagemResumo: { type: Number, default: 1 },
    instrumentosAnaliseTecnicaEmLote: { type: Array, default: () => [] },
  },
  data() {
    return {
      tipoCalibracaoId: null,
      flagSemConserto: null,
      keyAtualiza: 1,
      opcoes: {
        localExecucao: [],
        tipoCalibracaoEnum: [],
        booleano: [
          {
            text: 'Sim',
            value: 1,
          },
          {
            text: 'Não',
            value: 2,
          },
        ],
        possivelConserto: [
          {
            text: 'Sim',
            value: 6,
          },
          {
            text: 'Não, sem calibração',
            value: 'SemCalibracao',
          },
          {
            text: 'Não, sem conserto',
            value: 'SemConserto',
          },
          {
            text: 'Não compensa financeiramente',
            value: 'NaoCompensaFinanceiramente',
          },
          {
            text: 'Não, fora do escopo de calibração',
            value: 'ForaDoEscopoCalibracao',
          },
          {
            text: 'Não, fora do escopo de manutenção',
            value: 'ForaDoEscopoManutencao',
          },
        ],
        tipoPrazoEntrega: [
          {
            text: 'Contrato',
            value: 'Contrato',
          },
          {
            text: 'Requisitos do cliente',
            value: 'RequisitosCliente',
          },
          {
            text: 'Calculado por Setor',
            value: 'CalculadoSetor',
          },
          {
            text: 'Arbitrário',
            value: 'Arbitrario',
          },
        ],
      },
      tabelaCalibracao: {
        selecionados: [],
        colunas: [
        {
          value: 'instrumento.codigo',
          text: this.$t('modulos.operacao.tabela.instrumento'),
        },
        {
          value: 'faixaInstrumento',
          text: this.$t('modulos.operacao.tabela.faixa_instrumento'),
        },
        {
          value: 'codigo',
          text: this.$t('modulos.operacao.tabela.codigo_servico'),
        },
        {
          value: 'nome',
          text: this.$t('modulos.operacao.tabela.nome'),
        },
        {
          value: 'faixa',
          text: this.$t('modulos.operacao.tabela.faixa_servico'),
        },
        {
          value: 'minimoPontos',
          text: this.$t('modulos.operacao.tabela.quantidade_pontos'),
        },
        {
          value: 'tempoExecucao',
          text: this.$t('modulos.operacao.tabela.horas_execucao_padrao'),
          formatter: v => v && helpers.formatarNumeroComPrecisao(v)
        },
        {
          value: 'tempoExecucaoCustom',
          text: this.$t('modulos.operacao.tabela.horas_execucao'),
          formatter: v => v && helpers.formatarNumeroComPrecisao(v)
        },
        {
          value: 'contrato.codigo',
          text: this.$t('modulos.operacao.tabela.codigo_contrato'),
        }],
        paginaAtual: 1,
        porPagina: 5,
      },
      tabelaManutencao: {
        selecionados: [],
        colunas: [
        {
          value: 'instrumento.codigo',
          text: this.$t('modulos.operacao.tabela.instrumento'),
        },
        {
          value: 'faixaInstrumento',
          text: this.$t('modulos.operacao.tabela.faixa_instrumento'),
        },
        {
          value: 'codigo',
          text: this.$t('modulos.operacao.tabela.codigo_servico'),
        },
        {
          value: 'nome',
          text: this.$t('modulos.operacao.tabela.nome'),
        },
        {
          value: 'faixa',
          text: this.$t('modulos.operacao.tabela.faixa_servico'),
        },
        {
          value: 'tempoExecucao',
          text: this.$t('modulos.operacao.tabela.horas_execucao_padrao'),
          formatter: v => v && helpers.formatarNumeroComPrecisao(v)
        },
        {
          value: 'tempoExecucaoCustom',
          text: this.$t('modulos.operacao.tabela.horas_execucao'),
          formatter: v => v && helpers.formatarNumeroComPrecisao(v)
        },
        {
          value: 'ultimaExecucao',
          text: this.$t('modulos.operacao.tabela.ultima_execucao'),
          formatter: v => v ? helpers.formatarDataBr(v) : '',
        },
      ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 5,
      },
      tabelaPecas: {
        selecionados: [],
        colunas: [
          {
          value: 'instrumento.codigo',
          text: this.$t('modulos.operacao.tabela.instrumento'),
          },
          {
            value: 'codigo',
            text: this.$t('modulos.operacao.tabela.peca'),
            sorteable: true,
          },
          {
            value: 'descricao',
            text: this.$t('modulos.operacao.tabela.nome'),
            sorteable: true,
          },
          {
            value: 'participanteFabricante.nome',
            text: this.$t('modulos.operacao.tabela.fabricante'),
            sorteable: true,
          },
          {
            value: 'quantidadeEstoque',
            text: this.$t('modulos.operacao.tabela.quantidade_estoque'),
            sorteable: true,
          },
          {
            value: 'prazoEntrega',
            text: this.$t('modulos.operacao.tabela.prazo_entrega'),
            sorteable: true,
          },
          {
            value: 'tempoExecucao',
            text: this.$t('modulos.operacao.tabela.horas_execucao'),
            formatter: v => v && helpers.formatarNumeroComPrecisao(v),
            sorteable: true,
          },
          {
            value: 'quantidadeOrcada',
            text: this.$t('modulos.operacao.tabela.quantidade_orcada'),
            sorteable: true,
          },
          {
            value: 'ultimaExecucao',
            text: this.$t('modulos.operacao.tabela.ultima_execucao'),
            sorteable: true,
            formatter: v => v ? helpers.formatarDataBr(v) : '',
          },
        ],
        paginaAtual: 1,
        porPagina: 5,
      },
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  watch: {
    opcoesLocalExecucao() {
      this.opcoes.localExecucao = this.opcoesLocalExecucao;
    },
    opcoesTipoCalibracao() {
      this.opcoes.tipoCalibracaoEnum = this.opcoesTipoCalibracao;
    },
    atualizaListagemResumo() {
      this.keyAtualiza++;
    },
    'form.dadosCalibracaoResumo'() {
      this.tipoCalibracaoId =
        this.form.analisesTecnicas[0]?.calibracao?.tipoCalibracaoId;
    },
    flagSemConserto(valor) {
      if (valor && this.form.analisesTecnicas?.length) {
        this.form.analisesTecnicas.map((item) => {
          item.flagSemConserto = this.flagSemConserto;
        });
      }
    },
  },
  mounted() {
    this.flagSemConserto = 6;
  },
  methods: {
    voltaStep() {
      this.$emit('volta-step', 5);
    },
    montarDescricaoFaixa(codigoTag){
      if(!codigoTag) return;
      const codigo = this.instrumentosAnaliseTecnicaEmLote.find(el => el.codigoTag == codigoTag)?.descricaoFaixa
      return isNaN(parseFloat(codigo)) ? codigo : (codigo + ' Faixas');
    },
  },
};
</script>
