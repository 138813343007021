<template>
  <div>
    <div v-for="(item, index) in requisitosCliente" :key="index">
      <accordion-padrao
        :alterar-cor="true"
        cor-azul-texto
        :titulo="
          $t(
            `modulos.participantes.formulario.requisitos.${item.tipoRequisito}`
          )
        "
        class="accordion-padrao mb-5"
      >
        <div
          v-for="(requisitoItem, index) in item.requisitos"
          :key="index"
          class="d-flex"
        >
          <p>
            <span
              v-if="
                requisitoItem.classificacaoRequisito == 'CampoAdicional' ||
                requisitoItem.classificacaoRequisito == 'TextoLivre'
              "
              class="ml-10"
              v-html="requisitoItem.descricao"
            >
            </span>
          </p>
        </div>

        <accordion-padrao
          v-if="item.descricaoTipoDocumentoGeral"
          cor-azul-texto
          cor-texto="#0038ff"
          alterar-cor
          :titulo="'Documentos Necessários'"
          class="accordion-padrao ml-10"
        >
          <span
            :key="index"
            class="d-flex ml-10"
            v-html="item.descricaoTipoDocumentoGeral"
          >
          </span>
        </accordion-padrao>
      </accordion-padrao>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    requisitosCliente: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  watch: {
    requisitosCliente() {
      this.formataDadosParaExibicao();
    },
  },
  mounted() {
    this.formataDadosParaExibicao();
  },
  methods: {
    abrirModal: function () {
      this.$refs['modal-requisitos'].abrirModal();
    },
    formataDadosParaExibicao() {
      if (this.requisitosCliente) {
        this.requisitosCliente.map((item) => {
          item.descricaoTipoDocumentoGeral = '';
        });

        this.requisitosCliente.map((item) => {
          if (item.requisitos.length) {
            item.requisitos.map((itemRequisito) => {
              if (itemRequisito.classificacaoRequisito == 'TipoDocumento') {
                item.descricaoTipoDocumentoGeral += itemRequisito.descricao
                  ? itemRequisito.descricao + '<br>'
                  : '';
              }
            });
          }
        });
        this.requisitosCliente.map((item) => {
          if (item.requisitos.length) {
            item.requisitos.map((itemRequisito) => {
              itemRequisito.descricao = itemRequisito.descricao.replaceAll(
                '\n',
                '<br>&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp'
              );
            });
          }
        });
      }
    },
  },
};
</script>
<style>
.accordion-padrao {
  color: #0d47a1;
}
</style>
