function sortCompararPorAmplitude(a, b) {
  if (a.amplitudeMinima < b.amplitudeMinima) {
      return -1;
  }
  if (a.amplitudeMinima > b.amplitudeMinima) {
      return 1;
  }
  return 0;
  }
export class DropdownFaixas {
  constructor(lista) {
    if (Array.isArray(lista)){
      const listaDropDown = lista.map((faixa) => {
        return {
          value: faixa?.id,
          text: `${
            faixa?.descricao
          }`,
          amplitudeMinima: faixa?.amplitudeMinima,
        };
      });

      return listaDropDown.sort(sortCompararPorAmplitude)
    }
    return [];
  }  
}
