<template>
  <modal-padrao
    ref="modal-historico-servicos-pecas"
    :max-width="EhTelaPequena ? '90%' : '70%'"
    :titulo="$t('modulos.instrumento.botoes.historico_servicos_pecas')"
    :mostra-botao-salvar="false"
    :titulo-cancelar="$t('geral.botoes.voltar')"
  >
    <v-form
      class="row"
    >
      <input-text
        :value="instrumento"
        class="col-12 col-md-6"
        label="Instrumento"
        disabled
      />
      <input-text
        :value="tipoInstrumento"
        class="col-12 col-md-6"
        label="Tipo Instrumento"
        disabled
      />
      <input-text
        :value="cliente"
        class="col-12 col-md-12"
        label="Cliente"
        disabled
      />
    </v-form>
    <div style="min-height: 200px">
      <tabela-padrao-prime-vue
        v-model="selecionados"
        :dados="tabela.dados"
        filtro-geral
        class="mt-2"
        :mostrar-seletor="false"
        :colunas="tabela.colunas"
        paginacao-em-memoria
        sem-paginacao
        :filters="filters"
        ajustar-altura-linha
        :mostrar-acoes="false"
        :por-pagina="tabela.porPagina"
        :pagina-atual="tabela.paginaAtual"
        :global-filters="tabela.colunas.map(c => c.value)"  
      />
    </div>
  </modal-padrao>
</template>
  <script>
  import { FilterMatchMode } from 'primevue/api';
  import InstrumentoService from '@common/services/cadastros/InstrumentoService.js';
  import helpers from '@common/utils/helpers';
  
  export default {
    data() {
      return {
        filtro: {
          codigo: '',
        },
        filtroAplicado: { filter: '' },
        selecionados: [],
        tabela: {
          dados: [],
          colunas: [
            {
              value: 'ordemServico.codigo',
              text: this.$t('modulos.instrumento.tabela.ordem_servico'),
              sortable: true,
            },
            {
              value: 'tipoItem',
              text: this.$t('modulos.instrumento.tabela.tipo'),
              sortable: true,
            },
            {
              value: 'codigo',
              text: this.$t('modulos.instrumento.tabela.codigo'),
              sortable: true,
            },
            {
              value: 'nome',
              text: this.$t('modulos.instrumento.tabela.nome'),
              sortable: true,
            },
            {
              value: 'horasExecucao',
              text: this.$t('modulos.instrumento.tabela.horas_execucao'),
              sortable: true,
            },
            {
              value: 'ordemServico.quantidade',
              text: this.$t('modulos.instrumento.tabela.quantidade'),
              sortable: true,
            },
            {
              value: 'dataExecucao',
              text: this.$t('modulos.instrumento.tabela.data_execucao'),
              sortable: true,
              formatter: v => v ? helpers.formatarDataBr(v) : v,
            },
            {
              value: 'usuarioExecutante.nome',
              text: this.$t('modulos.instrumento.tabela.executante'),
              sortable: true,
            },
          ],
          porPagina: 5,
          paginaAtual: 2,
        },
        filters: {
          global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        },
        instrumento: "",
        cliente: "",
        tipoInstrumento: "",
      };
    },
    computed: {
      EhTelaPequena() {
        return ['sm', 'xs'].includes(this.$vuetify.breakpoint.name);
      },
    },
    methods: {
      abrirModal: function (instrumento) {
        this.buscarHistorico(instrumento.id);
        this.$refs['modal-historico-servicos-pecas'].abrirModal();
      },
      buscarHistorico(id){
        InstrumentoService.buscarHistoricoServicosPecas(id)
            .then((res) => {
          this.instrumento = res.data.instrumento?.codigo;
          this.cliente = res.data.participante?.nome;
          this.tipoInstrumento = res.data.tipoInstrumento?.nome;

          this.tabela.dados = res.data.detalhes;
        });
      },
    },
  };
  </script>
  