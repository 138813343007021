import { api } from '@/config/axios'

/**
 * Busca todos modelos de instrumentos
 */
export async function getModeloInstrumento(params) {
  let qs = new URLSearchParams()

  if (params) qs = new URLSearchParams({...params})

  return api.get('/modeloinstrumento', {
    params: qs
  }).then(response => {
    return response
  }, err => {
    return err
  })
}

/**
 * Busca todos modelos de instrumentos
 */
export async function getModeloInstrumentoModalTipoModelo(params) {
  let qs = new URLSearchParams()

  if (params) qs = new URLSearchParams(params)

  return api.get('/modeloinstrumento', {
    params: qs
  }).then(response => {
    return response
  }, err => {
    return err
  })
}

/**
 * Busca um modelo de instrumento por id
 * @param {String} id
 */
export async function getModeloInstrumentoById(id) {
  return api.get(`/modeloinstrumento/${id}`).then(response => {
    return response
  }, err => {
    return err
  })
}

/**
 * Cria uma novo modelo de instrumento
 * @param {Object} modeloInstrumento
 */
export async function createModeloInstrumento(modeloInstrumento) {
  return api.post('/modeloinstrumento/', modeloInstrumento).then(response => {
    return response
  }, err => {
    return err
  })
}

/**
 * Edita um modelo de instrumento
 * @param {Object} modeloInstrumento
 */
export async function updateModeloInstrumento({ id, form}) {
  return api.put(`/modeloinstrumento/${id}`, form).then(response => {
    return response
  }, err => {
    return err
  })
}

/**
 * Deleta um modelo de instrumento
 * @param {String} id
 */
export async function deleteModeloInstrumento(id) {
  return api.delete(`/modeloinstrumento/${id}`).then(response => {
    return response
  }, err => {
    return err
  })
}
