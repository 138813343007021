export class AnaliseTecnicaModelEmLote {
  constructor({
    id,
    analisesTecnicas,
    arrayInstrumentosIds,
    dadosListagemCalibracao,
    dadosListagemManutencao,
    dadosListagemPecas,
    dadosListagemChecklist,

    dadosCalibracaoResumo,
    dadosManutencaoResumo,
    dadosPecasResumo,

    localExecucaoId = null,
    tipoPrazoEntrega,
    prazoEntregaEmDias,
    descricaoJustificativaSemConserto = '',
    tipoCalibracaoId = null,
    // instrumentoId,
    // ordemServicoId,
    // valorTotal,
    // calibracao,
    // manutencoes,
    // pecas,
    // localExecucaoId,
    // prazoEntregaEmDias,
    // flagSemConserto,
    // flagGarantia,
    // justificativaGarantia,
    // flagTemServicoCalibracao,
    // pontosCalibracaoInstrumento = 0,
    // pontosExtras,
    // dadosCalibracaoResumo,
    // dadosManutencaoResumo,
    // dadosPecasResumo,
    // dadosChecklistResumo,
    // checklist,
    // flagFinalizada,
    // tipoPrazoEntrega,
    // justificativaSemConserto,
  }) {
    this.id = id;
    this.analisesTecnicas = analisesTecnicas;
    this.arrayInstrumentosIds = arrayInstrumentosIds;
    this.dadosListagemCalibracao = dadosListagemCalibracao;
    this.dadosListagemManutencao = dadosListagemManutencao;
    this.dadosListagemPecas = dadosListagemPecas;
    this.dadosListagemChecklist = dadosListagemChecklist;

    this.dadosCalibracaoResumo = dadosCalibracaoResumo;
    this.dadosManutencaoResumo = dadosManutencaoResumo;
    this.dadosPecasResumo = dadosPecasResumo;

    this.localExecucaoId = localExecucaoId;
    this.tipoPrazoEntrega = tipoPrazoEntrega;
    this.prazoEntregaEmDias = prazoEntregaEmDias;
    this.descricaoJustificativaSemConserto = descricaoJustificativaSemConserto;
    this.tipoCalibracaoId = tipoCalibracaoId;

    // this.instrumentoId = instrumentoId;
    // this.ordemServicoId = ordemServicoId;
    // this.valorTotal = valorTotal;
    // this.calibracao = calibracao;
    // this.manutencoes = manutencoes;
    // this.pecas = pecas;
    // this.localExecucaoId = localExecucaoId;
    // this.prazoEntregaEmDias = prazoEntregaEmDias;
    // this.flagSemConserto = flagSemConserto;
    // this.flagGarantia = flagGarantia;
    // this.justificativaGarantia = justificativaGarantia;
    // this.flagTemServicoCalibracao = flagTemServicoCalibracao;
    // this.pontosCalibracaoInstrumento = pontosCalibracaoInstrumento;
    // this.pontosExtras = pontosExtras;
    // this.dadosCalibracaoResumo = dadosCalibracaoResumo;
    // this.dadosManutencaoResumo = dadosManutencaoResumo;
    // this.dadosPecasResumo = dadosPecasResumo;
    // this.dadosChecklistResumo = dadosChecklistResumo;
    // this.checklist = checklist;
    // this.flagFinalizada = flagFinalizada;
    // this.tipoPrazoEntrega = tipoPrazoEntrega;
    // this.justificativaSemConserto = justificativaSemConserto;
  }
}
