<template>
  <div>
    <v-form ref="form">
      <div class="row mt-1">
        <input-select
          :value="form.localExecucaoId"
          class="col-12 col-md-4"
          :label="$t('modulos.operacao.formulario.local_execucao')"
          :options="opcoes.localExecucao"
          :loading="loadingLocalExecucao"
          :disabled="true"
        />
        <input-select
          v-model="form.tipoPrazoEntrega"
          class="col-12 col-md-4"
          :label="$t('modulos.operacao.formulario.tipo_prazo_entrega')"
          :options="opcoes.tipoPrazoEntrega"
          :loading="loadingLocalExecucao"
          :disabled="localExecucaoInstalacaoCliente"
          :obrigatorio="!localExecucaoInstalacaoCliente"
        />
        <input-text
          v-model="prazoEntregaEmDias"
          class="col-12 col-md-4"
          :label="$t('modulos.operacao.formulario.prazo_entrega')"
          type="number"
          :disabled="localExecucaoInstalacaoCliente"
          :obrigatorio="!localExecucaoInstalacaoCliente"
        />
      </div>
      <div class="row mt-1">
        <div class="col-md-2">
          {{ $t('modulos.operacao.formulario.possui_calibracao') }}
          <v-switch
            v-model="flagTemServicoCalibracao"
          />
        </div>
        <input-select
          :key="keyAtualiza"
          v-model="form.tipoCalibracaoId"
          class="col-md-4"
          :label="$t('modulos.operacao.formulario.tipo_calibracao')"
          :options="opcoes.tipoCalibracaoEnum"
          :disabled="!flagTemServicoCalibracao"
          :obrigatorio="flagTemServicoCalibracao"
        />
      </div>
      <div class="col-12">
        Selecione os serviços que deseja executar no instrumentos.
        <tabela-padrao-prime-vue
          :key="keyAtualizaTabela"
          v-model="tabelaCalibracao.selecionados"
          :dados="form.dadosListagemCalibracao"
          class="mt-2"
          :colunas="tabelaCalibracao.colunas"
          sem-paginacao
          :mostrar-seletor="flagTemServicoCalibracao"
          paginacao-em-memoria
          ajustar-altura-linha
          ajustar-dropdown-acima
          :mostrar-acoes="false"
          :por-pagina="tabelaCalibracao.porPagina"
          :pagina-atual="tabelaCalibracao.paginaAtual"
          filtro-geral
          :filters="filters"
          :global-filters="tabelaCalibracao.colunas.map(c => c.value)"
        >
          <template v-slot:tempoExecucaoCustom="{ slotProps }">
            <input-text
              v-model="slotProps.data.tempoExecucaoCustom"
              sem-label
              flat
              solo
              type="number"
              class="flex-fill input-tabela-analise-tecnica my-1"
              @input="montaObjetoForm"
            />
          </template>
          <template v-slot:faixaInstrumento="{ slotProps }">
            <span> {{ montarDescricaoFaixa(slotProps.data.instrumento?.codigo) }}</span>
          </template>
          >
          <template v-slot:faixa="{ slotProps }">
            <div
              v-if="slotProps.data.amplitudeMinima || slotProps.data?.amplitudeMaxima"
              class="alinhar-direita"
            >
              {{
                `(${
                  slotProps.data?.amplitudeMinima ? slotProps.data.amplitudeMinima + ' a ' : ''
                }${
                  slotProps.data?.amplitudeMaxima || '...'
                }) ${
                  slotProps.data?.unidadeMedida?.simbolo || ''
                }`
              }}
            </div>
          </template>
        </tabela-padrao-prime-vue>
      </div>
      <div
        class="d-flex mt-4"
        style="justify-content: space-between"
      >
        <botao-padrao
          color="secondary"
          outlined
          @click="voltaStep()"
        >
          <v-icon>$mdiArrowLeft</v-icon>
          {{ $t('modulos.contrato.formulario.itensContrato.steps.voltar') }}
        </botao-padrao>

        <botao-padrao
          color="primary"
          @click="validaFormulario()"
        >
          {{ $t('modulos.contrato.formulario.itensContrato.steps.proximo') }}
          <v-icon>$arrowRight</v-icon>
        </botao-padrao>
      </div>
    </v-form>
  </div>
</template>
<script>
import { InstrumentoModel } from '@common/models/cadastros/InstrumentoModel';
import InstrumentoService from '@common/services/cadastros/InstrumentoService.js';
import { FilterMatchMode } from 'primevue/api';
import AnaliseTecnicaService from '@common/services/cadastros/AnaliseTecnicaService';
import _ from 'lodash';
import helpers from '../../../../../common/utils/helpers';
export default {
  components: {},
  props: {
    form: { type: Object, default: () => ({}) },
    dadosCalibracaoSelecionados: { type: Array, default: () => [] },
    opcoesLocalExecucao: { type: Array, default: () => [] },
    opcoesTipoCalibracao: { type: Array, default: () => [] },
    prazoGarantia: { type: Number, default: null },
    atualizaListagemCalibracao: { type: Number, default: 1 },
    atualizaDadosAnaliseLoteInstrumentos: { type: Number, default: 1 },
    participanteId: { type: String, default: null },
    instrumentoId: { type: String, default: null },
    tipoInstrumentoIdSelecionado: { type: String, default: null },
    clienteParticipanteOrdemServicoId: { type: String, default: null },
    ordemServicoId: { type: String, default: null },
    codigoInstrumento: { type: String, default: null },
    idTipoCalibracao: { type: String, default: null },
    codigoOrdemServico: { type: String, default: null },
    nomeTipoInstrumento: { type: String, default: null },
    desabilitaGarantia: { type: Boolean, default: null },
    flagServicoCalibracao: { type: Boolean, default: null },
    localExecucaoInstalacaoCliente: { type: Boolean, default: null },
    instrumentosAnaliseTecnicaEmLote: { type: Array, default: () => [] },
    },
  data() {
    return {
      keyAtualiza: 1,
      keyAtualizaTabela: 100,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      formInstrumento: null,
      precoServicoContrato: null,
      codigoString: '',
      loadingTipoCalibracao: false,
      loadingLocalExecucao: false,
      opcoes: {
        localExecucao: [],
        tipoCalibracaoEnum: [],
        booleano: [
          {
            text: 'Sim',
            value: 1,
          },
          {
            text: 'Não',
            value: 2,
          },
        ],
        tipoPrazoEntrega: [
          {
            text: 'Contrato',
            value: 'Contrato',
          },
          {
            text: 'Requisitos do cliente',
            value: 'RequisitosCliente',
          },
          {
            text: 'Calculado por Setor',
            value: 'CalculadoSetor',
          },
          {
            text: 'Arbitrário',
            value: 'Arbitrario',
          },
        ],
      },
      tabelaCalibracao: {
        selecionados: [],
        dados: [],
        colunas: [
        {
          value: 'instrumento.codigo',
          text: this.$t('modulos.operacao.tabela.instrumento'),
        },
        {
          value: 'faixaInstrumento',
          text: this.$t('modulos.operacao.tabela.faixa_instrumento'),
        },
        {
          value: 'codigo',
          text: this.$t('modulos.operacao.tabela.codigo_servico'),
        },
        {
          value: 'nome',
          text: this.$t('modulos.operacao.tabela.nome'),
        },
        {
          value: 'faixa',
          text: this.$t('modulos.operacao.tabela.faixa_servico'),
        },
        {
          value: 'minimoPontos',
          text: this.$t('modulos.operacao.tabela.quantidade_pontos'),
        },
        {
          value: 'tempoExecucao',
          text: this.$t('modulos.operacao.tabela.horas_execucao_padrao'),
          formatter: v => v && helpers.formatarNumeroComPrecisao(v)
        },
        {
          value: 'tempoExecucaoCustom',
          text: this.$t('modulos.operacao.tabela.horas_execucao'),
        },
        {
          value: 'contrato.codigo',
          text: this.$t('modulos.operacao.tabela.codigo_contrato'),
        }],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      validaValorAnteriorCalibracao: false,
      temUltimoServicoCalibracao: false,
      idUltimoServicoCalibracao: null,
      localExecucaoId: null,
      tipoPrazoEntrega: null,
      prazoEntregaEmDias: null,
      flagTemServicoCalibracao: null,
      tipoCalibracaoId: null,
      instrumentoString: null,
    };
  },
  watch: {
    'form.tipoCalibracaoId'(valorAtual, valorAnterior) {
      if(!valorAnterior) return this.listarDadosAnaliseTecnicaEmLote();
      if (!valorAtual || !valorAnterior) return;

      if (this.validaValorAnteriorCalibracao) {
        this.validaValorAnteriorCalibracao = false;
        return;
      }
      if (!this.form.dadosListagemCalibracao?.length) return this.listarDadosAnaliseTecnicaEmLote();

      this.confirmarTrocaTipoCalibracao()
        .then(() => {
          this.tabelaCalibracao.selecionados = null;
          this.listarDadosAnaliseTecnicaEmLote();
        })
        .catch(() => {
          this.form.tipoCalibracaoId = valorAnterior;
          this.validaValorAnteriorCalibracao = true;
          return;
        });
    },
    'form.localExecucaoId'(valor, valorAnterior) {
      if (valor) {
        if (this.form.analisesTecnicas?.length) {
          this.form.analisesTecnicas.map((item) => {
            item.localExecucaoId = this.form.localExecucaoId;
          });
        }
      }

      if (!valorAnterior) {
        return;
      }
      if (this.validaValorAnteriorCalibracao) {
        this.validaValorAnteriorCalibracao = false;
        return;
      }
      if (this.form.dadosListagemCalibracao?.length) {
        this.confirmarTrocaLocal()
          .then(() => {
            this.tabelaCalibracao.selecionados = null;
            this.validaDadosParaListarAnaliseTecnicaEmLote();
          })
          .catch(() => {
            this.form.localExecucaoId = valorAnterior;
            this.validaValorAnteriorCalibracao = true;
            return;
          });
      }
    },
    'form.prazoEntregaEmDias'(valor) {
      if (valor) {
        this.prazoEntregaEmDias = this.form.prazoEntregaEmDias;
      }
    },
    flagServicoCalibracao(valor) {
      if (valor) {
        this.flagTemServicoCalibracao = this.form.flagTemServicoCalibracao;
        this.validaDadosParaListarAnaliseTecnicaEmLote();
      }
    },
    opcoesLocalExecucao() {
      this.opcoes.localExecucao = this.opcoesLocalExecucao;
    },
    opcoesTipoCalibracao() {
      this.opcoes.tipoCalibracaoEnum = this.opcoesTipoCalibracao;
    },
    'form.tipoPrazoEntrega'(valor) {
      if (valor) {
        this.form.analisesTecnicas.map((item) => {
          item.tipoPrazoEntrega = this.form.tipoPrazoEntrega;
        });
      }
    },
    prazoEntregaEmDias(valor) {
      if (valor) {
        this.form.prazoEntregaEmDias = this.prazoEntregaEmDias;
        this.form.analisesTecnicas.map((item) => {
          item.prazoEntregaEmDias = this.prazoEntregaEmDias;
        });
      }
    },
    flagTemServicoCalibracao(valor) {
      if (valor) {
        this.keyAtualiza++;
        this.validaDadosParaListarAnaliseTecnicaEmLote();
      } else {
        this.form.analisesTecnicas.map((item) => {
          item.flagTemServicoCalibracao = false;
          item.calibracao = null;
        });
        this.tabelaCalibracao.selecionados = null;
        this.tabelaCalibracao.dados = [];
        this.form.dadosListagemCalibracao = [];
        this.validaValorAnteriorCalibracao = true;
        this.form.tipoCalibracaoId = null
        this.validaDadosParaListarAnaliseTecnicaEmLoteSemCalibracao();
      }
      this.keyAtualizaTabela++;
    },
    tipoInstrumentoIdSelecionado(valor) {
      if (valor) {
        this.validaDadosParaListarAnaliseTecnicaEmLote();
      }
    },
    atualizaDadosAnaliseLoteInstrumentos() {
      this.validaDadosParaListarAnaliseTecnicaEmLote();
      this.form.analisesTecnicas.map((item) => {
        item.localExecucaoId = this.form.localExecucaoId;
        item.tipoPrazoEntrega = this.form.tipoPrazoEntrega;
        item.prazoEntregaEmDias = this.prazoEntregaEmDias;
        item.flagTemServicoCalibracao = this.flagTemServicoCalibracao;
      });
    },
  },
  mounted() {
    this.flagTemServicoCalibracao = true;
  },
  methods: {
    montarDescricaoFaixa(codigoTag){
      if(!codigoTag) return;
      const codigo = this.instrumentosAnaliseTecnicaEmLote.find(el => el.codigoTag == codigoTag)?.descricaoFaixa
      return isNaN(parseFloat(codigo)) ? codigo : (codigo + ' Faixas');
    },
    selecionaCalibracaoDaEdicao() {
      if (this.form.dadosListagemCalibracao?.length) {
        this.tabelaCalibracao.selecionados = [];
        this.form.dadosListagemCalibracao.forEach((item) => {
          this.dadosCalibracaoSelecionados.forEach((itemSelecionado) => {
            if (
              item.servicoId == itemSelecionado.servicoId &&
              item.instrumento.id == itemSelecionado.instrumentoId
            ) {
              if (!this.tabelaCalibracao.selecionados.includes(item)){
                this.tabelaCalibracao.selecionados.push(item);
                item.tempoExecucaoCustom = itemSelecionado.horasExecucao;
              }
            }
          });
        });
      }
    },
    voltaStepValidacao: async function(){
      await this.$nextTick();
      this.$emit('proximo-step', 2);
      return false;
    },
    validaFormulario(emitirEvento = true) {
      if (!this.$refs.form.validate()) return this.voltaStepValidacao();
      if (this.flagTemServicoCalibracao && !this.tabelaCalibracao.selecionados?.length) {
        this.toastAlerta(
          this.$t(
            `modulos.operacao.erros.selecione_servico_calibracao_instrumento`
          )
        );
        return this.voltaStepValidacao();
      }

      if (!this.tabelaCalibracao.selecionados?.length) return;
      var idsInstrumentosSelecionados = [];
      this.tabelaCalibracao.selecionados.forEach((item) => {
        idsInstrumentosSelecionados.push(item.instrumento.id);
      });

      let instrumentosDuplicados = idsInstrumentosSelecionados.some(
        (val, i) => idsInstrumentosSelecionados.indexOf(val) !== i
      );

      if (this.flagTemServicoCalibracao && instrumentosDuplicados) {
        this.toastAlerta(this.$t(`modulos.operacao.erros.selecione_servico_calibracao_instrumento`));
        return this.voltaStepValidacao();
      }

      if (
        this.flagTemServicoCalibracao &&
        this.tabelaCalibracao.selecionados.length !==
          this.form.arrayInstrumentosIds.length
      ) {
        this.toastAlerta(
          this.$t(
            `modulos.operacao.erros.selecione_servico_calibracao_instrumento`
          )
        );
        return this.voltaStepValidacao();
      }

      this.montaObjetoForm();

      if(emitirEvento) this.$emit('valida-dados-analise-tecnica');
      return true;
    },
    montaObjetoForm() {
      this.form.dadosCalibracaoResumo = [];
      if (this.tabelaCalibracao.selecionados?.length) {
        this.form.dadosCalibracaoResumo = this.tabelaCalibracao.selecionados;

        this.tabelaCalibracao.selecionados.forEach((item) => {
          this.form.analisesTecnicas.map((itemAnalise) => {
            if (item.instrumento.id == itemAnalise.instrumentoId) {
              itemAnalise.flagTemServicoCalibracao = true;
              itemAnalise.calibracao = {
                tipoCalibracaoId: item.tipoCalibracao.id,
                servicoId: item.servicoId,
                pontosItens: item.minimoPontos,
                valor: item.valorServico,
                tempoExecucao: item.tempoExecucaoCustom,
                horasExecucao: item.tempoExecucaoCustom,
              };
            }
          });
        });
      }
    },
    validaDadosParaListarAnaliseTecnicaEmLoteSemCalibracao() {
      if (
        this.form.localExecucaoId &&
        this.tipoInstrumentoIdSelecionado &&
        this.form.arrayInstrumentosIds.length
      ) {
        this.listarDadosAnaliseTecnicaEmLote();
      }
    },
    validaDadosParaListarAnaliseTecnicaEmLote() {
      if (
        this.flagTemServicoCalibracao &&
        this.form.localExecucaoId &&
        this.form.tipoCalibracaoId &&
        this.tipoInstrumentoIdSelecionado &&
        this.form.arrayInstrumentosIds.length
      ) {
        this.listarDadosAnaliseTecnicaEmLote();
      }
    },
    listarDadosAnaliseTecnicaEmLote() {
      if(!this.form.localExecucaoId) return;
      this.instrumentoString = '';
      this.form.arrayInstrumentosIds.map((item) => {
        this.instrumentoString += `&instrumentosId=${item}`;
      });
      let params = {
        ordemServicoId: this.ordemServicoId,
        localExecucaoId: this.form.localExecucaoId,
      };
      if (this.form.tipoCalibracaoId) {
        params.tipoCalibracaoId = this.form.tipoCalibracaoId;
      }
      this.$store.dispatch('Layout/iniciarCarregamento');
      AnaliseTecnicaService.buscarDadosViewModelAnaliseTecnicaEmLote(
        params,
        this.instrumentoString,
        this.tipoInstrumentoIdSelecionado
      )
        .then((res) => {
          if (res.data.servicosCalibracao.length && this.flagTemServicoCalibracao) {
            const dadosCalibracao = _.cloneDeep(res.data.servicosCalibracao);
            dadosCalibracao.forEach(servico => {
              const servicoExistente = this.form.dadosListagemCalibracao?.
                find(el => el.servicoId == servico.id && el.instrumento?.codigo == servico.instrumento?.codigo);
              servico.tempoExecucaoCustom = servicoExistente?.tempoExecucaoCustom ?? servico.tempoExecucao;

              const id = helpers.gerarNumeroAleatorio();
              servico.servicoId = servico.id;
              servico.id = servicoExistente?.id ?? id;
            });

            const refDados = []
            this.tabelaCalibracao.selecionados?.forEach(el => {
              const idsServicos = dadosCalibracao.map(el => el.id);
              if(!idsServicos.includes(el.id)) refDados.push(el);
            });

            refDados.forEach(el => {
              this.tabelaCalibracao.selecionados.splice(this.tabelaCalibracao.selecionados.indexOf(el), 1);
            });
            this.form.dadosListagemCalibracao = dadosCalibracao;

          } else {
            this.form.dadosListagemCalibracao = [];
          }
          if (res.data.servicosManutencao.length) {
            const dadosManutencao = _.cloneDeep(res.data.servicosManutencao);
            dadosManutencao.forEach(servico => {
              const servicoExistente = this.form.dadosListagemManutencao?.
                find(el => el.servicoId == servico.id && el.instrumento?.codigo == servico.instrumento?.codigo);
              servico.tempoExecucaoCustom = servicoExistente?.tempoExecucaoCustom ?? servico.tempoExecucao;

              const id = helpers.gerarNumeroAleatorio();
              servico.servicoId = servico.id;
              servico.id = servicoExistente?.id ?? id;
            });
            this.form.dadosListagemManutencao = dadosManutencao;
          } else {
            this.form.dadosListagemManutencao = [];
          }
          if (res.data.itens.length) {
            const pecasExistentes = _.cloneDeep(this.form.dadosListagemPecas);
            this.form.dadosListagemPecas = res.data.itens;
            this.form.dadosListagemPecas.map((item) => {
              const pecaExistente = pecasExistentes?.find(el => el.id == item.id);
              item.quantidadeOrcada = pecaExistente?.quantidadeOrcada ?? 1;
              const id = helpers.gerarNumeroAleatorio();
              item.pecaId = item.id;
              item.id = id;
            });
          }
          if (res.data.checkLists.length) {
            this.form.dadosListagemChecklist = _.sortBy(res.data.checkLists[0].itens, 'sequencia');

            this.form.dadosListagemChecklist.map((item) => {
              if (this.form.dadosListagemChecklistEdicao?.length) {
                this.form.dadosListagemChecklistEdicao.forEach((itemEdicao) => {
                  if (item.acaoNaoOk == itemEdicao.acaoNaoOk) {
                    item.flagOk = itemEdicao.flagOk;
                    item.check = itemEdicao.flagOk;
                    item.idEdicao = itemEdicao.id;
                  }
                });
                this.$emit('atualiza-radio-checklist');
              }
            });
          }
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          if (this.dadosCalibracaoSelecionados.length) {
            this.selecionaCalibracaoDaEdicao();
          }
        });
    },
    voltaStep() {
      this.$emit('volta-step', 1);
    },
    async abreModalFaixa() {
      await InstrumentoService.buscar(this.instrumentoId).then((res) => {
        this.formInstrumento = new InstrumentoModel(res.data);
      });
      await this.$refs['modal-faixa'].abrirModal();
    },
  },
};
</script>
<style scoped>
.tabela-padrao-com-acoes td:nth-child(2),
.tabela-padrao-com-acoes th:nth-child(2) {
  width: 0;
}
.tabela-padrao-com-seletor td:nth-child(1),
.tabela-padrao-com-seletor th:nth-child(1) {
  width: 0;
}
.tabela-prime-vue-inherit {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
}
</style>
