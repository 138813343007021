import { api } from '@/config/axios';

/**
 * Busca todos os tipo de instrumento
 */
export async function getTipoInstrumento(params) {
  let qs = new URLSearchParams();

  if (params) qs = new URLSearchParams({ ...params });
  return api
    .get('/tipoinstrumento', {
      params: qs,
    })
    .then(
      (response) => {
        return response;
      },
      (err) => {
        return err;
      }
    );
}

/**
 * Busca um tipoinstrumento por id
 * @param {String} id
 */
export async function getTipoInstrumentoById(id) {
  return api.get(`/tipoinstrumento/${id}`).then(
    (response) => {
      return response;
    },
    (err) => {
      return err;
    }
  );
}

/**
 * Cria uma novo tipoinstrumento
 * @param {Object} tipoinstrumento
 */
export async function createTipoInstrumento(tipoinstrumento) {
  return api.post('/tipoinstrumento/', tipoinstrumento).then(
    (response) => {
      return response;
    },
    (err) => {
      return err;
    }
  );
}

/**
 * Edita um tipoinstrumento
 * @param {Object} tipoinstrumento
 */
export async function updateTipoInstrumento(tipoinstrumento) {
  let { id, form } = tipoinstrumento;

  return api.put(`/tipoinstrumento/${id}`, form).then(
    (response) => {
      return response;
    },
    (err) => {
      return err;
    }
  );
}

/**
 * Deleta um tipoinstrumento
 * @param {String} id
 */
export async function deleteTipoInstrumento(id) {
  return api.delete(`/tipoinstrumento/${id}`).then(
    (response) => {
      return response;
    },
    (err) => {
      return err;
    }
  );
}
