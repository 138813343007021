<template>
  <div>
    <v-form ref="form">
      <div class="mt-10">
        Selecione os instrumentos que deseja inserir na análise técnica em lote.
        <tabela-padrao-prime-vue
          v-model="tabelaInstrumentos.selecionados"
          :dados="instrumentosAnaliseTecnicaEmLote"
          filtro-geral
          class="mt-2"
          :colunas="tabelaInstrumentos.colunas"
          paginacao-em-memoria
          sem-paginacao
          :filters="filters"
          ajustar-altura-linha
          ajustar-dropdown-acima
          :mostrar-acoes="true"
          :por-pagina="tabelaInstrumentos.porPagina"
          :pagina-atual="tabelaInstrumentos.paginaAtual"
          :global-filters="tabelaInstrumentos.colunas.map((c) => c.value)"
        >
          <template #acoes="{ slotProps }">
            <dropdown-padrao text color="secondary">
              <template #botao>
                <v-icon> $dotsVertical </v-icon>
              </template>
              <dropdown-padrao-item
                v-if="buscarPermissao('Instrumento', 'Editar')"
                @click="abreModalEditarInstrumento(slotProps.data)"
              >
                {{ $t('modulos.operacao.formulario.instrumento') }}
              </dropdown-padrao-item>
              <dropdown-padrao-item
                v-if="buscarPermissao('Instrumento', 'Editar')"
                @click="abreModalNovoPontoCalibracao(slotProps.data)"
              >
                {{ $t('modulos.instrumento.tabela.pontos_de_calibracao') }}
              </dropdown-padrao-item>
              <dropdown-padrao-item
                v-if="buscarPermissao('CriterioAceitacao', 'Editar')"
                @click="abreModalCriteriosAceitacao(slotProps.data)"
              >
                {{ $t('modulos.operacao.formulario.criterios_aceitacao') }}
              </dropdown-padrao-item>
              <dropdown-padrao-item
                v-if="buscarPermissao('Instrumento', 'VisualizarHistorico')"
                @click="abrirInstrumentoHistoricoServicosPecas(slotProps.data)"
              >
                {{ $t('modulos.instrumento.botoes.historico_servicos_pecas') }}
              </dropdown-padrao-item>
            </dropdown-padrao>
          </template>
        </tabela-padrao-prime-vue>
      </div>
      <div class="d-flex justify-end mt-2">
        <botao-padrao color="primary" @click="() => $emit('proximo-step', 2)">
          {{ $t('modulos.contrato.formulario.itensContrato.steps.proximo') }}
          <v-icon>$arrowRight</v-icon>
        </botao-padrao>
      </div>
    </v-form>

    <instrumento-edicao-modal
      ref="modal-edicao-instrumento"
      :instrumento-id="instrumentoId"
      :atualiza="true"
      @atualiza-instrumento-analise-tecnica="atualizaInstrumentoAnaliseTecnica"
    />
    <novo-ponto-calibracao-modal
      ref="modal-novo-ponto-calibracao"
      :instrumento-id="instrumentoId"
      :nome-tipo-instrumento="nomeTipoInstrumento"
      :codigo-instrumento="codigoInstrumento"
      :codigo-ordem-servico="codigoOrdemServico"
    />
    <criterios-aceitacao-modal
      ref="modal-criterios-aceitacao"
      :instrumento-id="instrumentoId"
      :codigo-instrumento="codigoInstrumento"
    />
    <ModalServicosPecas ref="modal-historico-servicos-pecas" />
  </div>
</template>
<script>
import NovoPontoCalibracaoModal from './modais/NovoPontoCalibracaoModal.vue';
import CriteriosAceitacaoModal from './modais/CriteriosAceitacaoModal.vue';
import InstrumentoEdicaoModal from './modais/InstrumentoEdicaoModal.vue';
import { FilterMatchMode } from 'primevue/api';
import ModalServicosPecas from '../../instrumento/components/modais/ModalServicosPecas.vue';
import helpers from '@/common/utils/helpers';
export default {
  components: {
    InstrumentoEdicaoModal,
    NovoPontoCalibracaoModal,
    CriteriosAceitacaoModal,
    ModalServicosPecas,
  },
  props: {
    form: { type: Object, default: () => ({}) },
    instrumentosAnaliseTecnicaEmLote: { type: Array, default: () => [] },
    codigoOrdemServico: { type: String, default: '' },
    nomeTipoInstrumento: { type: String, default: '' },
    ordemServicoId: { type: String, default: '' },
  },
  data() {
    return {
      instrumentoId: null,
      codigoInstrumento: null,
      validaHorasExecucao: true,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      totalRegistros: 0,
      flagInstrumentosAlterados: true,
      tabelaInstrumentos: {
        selecionados: [],
        colunas: [
          {
            value: 'codigoTag',
            text: this.$t('modulos.operacao.tabela.codigo'),
          },
          {
            value: 'tipoInstrumento.nome',
            text: this.$t('modulos.operacao.tabela.tipo_instrumento'),
          },
          {
            value: 'marca.nome',
            text: this.$t('modulos.operacao.tabela.marca'),
          },
          {
            value: 'modeloInstrumento.nome',
            text: this.$t('modulos.operacao.tabela.modelo'),
          },
          {
            value: 'numeroSerie',
            text: this.$t('modulos.operacao.tabela.numero_serie'),
          },
          {
            value: 'descricaoFaixa',
            text: this.$t('modulos.operacao.tabela.faixas'),
          },
        ],
        paginaAtual: 1,
        porPagina: 10,
      },
    };
  },
  watch: {
    instrumentosAnaliseTecnicaEmLote() {
      this.totalRegistros = this.instrumentosAnaliseTecnicaEmLote.length;
    },
    'tabelaInstrumentos.selecionados'() {
      this.flagInstrumentosAlterados = true;
      // if (
      //   this.tabelaInstrumentos.selecionados.length ==
      //   this.instrumentosAnaliseTecnicaEmLote.length
      // ) {
      this.tabelaInstrumentos.selecionados.forEach((item) => {
        let itemObjeto = {};
        itemObjeto.data = { ...item };
        this.selecionaItemTabela(itemObjeto);
      });
      // }
    },
  },
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
    abrirInstrumentoHistoricoServicosPecas: function (item) {
      this.$refs['modal-historico-servicos-pecas'].abrirModal(item);
    },
    atualizaInstrumentoAnaliseTecnica() {
      this.$emit('atualiza-listagem-instrumentos');
    },
    selecionaItemTabela(item) {
      if (!this.form.arrayInstrumentosIds.includes(item.data.instrumentoId)) {
        this.form.analisesTecnicas.push({
          analiseTecnicaId: null,
          instrumentoId: item.data.instrumentoId,
          ordemServicoId: this.ordemServicoId,
          flagSemConserto: 6,
          flagGarantia: false,
          justificativaGarantia: null,
          calibracao: null,
          checklist: [],
          manutencoes: [],
          pecas: [],
        });
        this.form.arrayInstrumentosIds.push(item.data.instrumentoId);
        this.form.tipoInstrumento = item.data.tipoInstrumento?.nome;
      }
    },
    voltaStepValidacao: async function () {
      await this.$nextTick();
      this.$emit('proximo-step', 1);
    },
    montaObjetoForm() {
      let idsAnalises = [];
      this.form.analisesTecnicas.forEach((item) => {
        idsAnalises.push(item.instrumentoId);
      });
      let idsselecionados = [];
      this.tabelaInstrumentos.selecionados.forEach((item) => {
        idsselecionados.push(item.instrumentoId);
      });
      let difference = idsAnalises.filter((x) => !idsselecionados.includes(x));

      this.form.analisesTecnicas = this.form.analisesTecnicas.filter(
        (x) => !difference.includes(x.instrumentoId)
      );
      if (difference?.length) {
        this.form.arrayInstrumentosIds = [];
        idsselecionados.forEach((item) => {
          this.form.arrayInstrumentosIds.push(item);
        });
      }
      this.$emit('atualiza-dados-analise-lote');
    },
    validaTiposInstrumentosIguais() {
      if (!this.flagInstrumentosAlterados) return;
      let tipoInstrumentoId = '';
      let tipoInstrumentosIguais = true;
      if (!this.tabelaInstrumentos.selecionados.length) {
        this.toastAlerta(
          this.$t(`modulos.operacao.erros.selecione_instrumento`)
        );
        return this.voltaStepValidacao();
      }

      tipoInstrumentoId =
        this.tabelaInstrumentos.selecionados[0]?.tipoInstrumento?.id;
      tipoInstrumentosIguais = this.tabelaInstrumentos.selecionados.some(
        (el) => el.tipoInstrumento.id != tipoInstrumentoId
      );

      if (tipoInstrumentosIguais) {
        this.toastAlerta(
          this.$t(`modulos.operacao.erros.tipos_instrumentos_iguais`)
        );
        return this.voltaStepValidacao();
      }
      this.$emit('envia-tipo-instrumento', tipoInstrumentoId);
      this.montaObjetoForm();
      this.flagInstrumentosAlterados = false;
    },
    abreModalEditarInstrumento(instrumento) {
      this.instrumentoId = instrumento.instrumentoId;
      this.$refs['modal-edicao-instrumento'].abrirModal();
    },
    abreModalNovoPontoCalibracao(instrumento) {
      this.instrumentoId = instrumento.instrumentoId;
      this.codigoInstrumento = instrumento.codigoTag;
      this.$refs['modal-novo-ponto-calibracao'].abrirModal();
    },
    abreModalCriteriosAceitacao(instrumento) {
      this.instrumentoId = instrumento.instrumentoId;
      this.codigoInstrumento = instrumento.codigoTag;
      this.$refs['modal-criterios-aceitacao'].abrirModal();
    },
  },
};
</script>
<style scoped>
.tabela-padrao-com-acoes td:nth-child(2),
.tabela-padrao-com-acoes th:nth-child(2) {
  width: 0;
}
.tabela-padrao-com-seletor td:nth-child(1),
.tabela-padrao-com-seletor th:nth-child(1) {
  width: 0;
}
.tabela-prime-vue-inherit {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
}
</style>
