<template>
  <BaseStepperModal
    v-if="[1, 2].includes(currentModalStep)"
    :id="id"
    :show="showModal"
    :title="modalTitle"
    close-direction="left"
    :current-step="currentModalStep"
    :width="880"
    :steps="modalTitles.length"
    :step-titles="modalTitles"
    :cancel-button-text="
      $t('components.theTipoModeloInstrumentoModal.buttons.cancel')
    "
    :previous-button-text="
      $t('components.theTipoModeloInstrumentoModal.buttons.previous')
    "
    :confirm-button-text="
      $t('components.theTipoModeloInstrumentoModal.buttons.confirm')
    "
    :next-button-text="
      $t('components.theTipoModeloInstrumentoModal.buttons.next')
    "
    :next-confirm-enabled="enabledNextConfirmButton"
    @close-modal="closeModal"
    @change-step="changeModalStep"
    @last-step="changeModalStep($event, 'last')"
  >
    <template v-slot:step-1>
      <div>
        <div>
          <tabela-padrao-prime-vue
            v-model="tableTiposInstrumentosSelected"
            class="mt-2"
            :colunas="tableTiposInstrumentos.headers"
            :dados="tableTiposInstrumentos.items"
            filtros-com-colunas
            :quantidade-itens="tableTiposInstrumentos.metadata.totalItems"
            :por-pagina="tableTiposInstrumentos.metadata.amountPerPage"
            :quantidade-paginas="tableTiposInstrumentos.metadata.totalPages"
            :mostrar-acoes="false"
            ajustar-altura-linha
            @filtroGlobal="v => tipoModeloInstrumento.filter = v"
            @filtrarGlobal="filterTipoModelo('tipo')"
            @paginar="listaTipoInstrumentos"
          >
            <template v-slot:tag="{ slotProps }">
              <coluna-multiplos-itens
                v-if="slotProps.data.tag?.length"
                :item="MontarViewTags(slotProps.data.tag)"
              />
            </template>
          </tabela-padrao-prime-vue>
        </div>
      </div>
    </template>

    <template v-slot:step-2>
      <tabela-padrao-prime-vue
        v-model="tableModelosInstrumentosSelected"
        class="mt-2"
        :colunas="tableModelosInstrumentos.headers"
        :dados="tableModelosInstrumentos.items"
        filtros-com-colunas
        :quantidade-itens="tableModelosInstrumentos.metadata.totalItems"
        :por-pagina="tableModelosInstrumentos.metadata.amountPerPage"
        :quantidade-paginas="tableModelosInstrumentos.metadata.totalPages"
        :mostrar-acoes="false"
        ajustar-altura-linha
        @filtroGlobal="v => tipoModeloInstrumento.filter = v"
        @filtrarGlobal="filterTipoModelo('modelo')"
        @paginar="listaModeloInstrumentos"
      />
    </template>
  </BaseStepperModal>
</template>

<script>
import { getTipoInstrumento } from '@api/tipoInstrumento';
import { getModeloInstrumentoModalTipoModelo } from '@api/modeloInstrumento';
import UnidadeMedidaService from '@common/services/cadastros/UnidadeMedidaService.js';
import ColunaMultiplosItens from '@/components/tabelas/ColunaMultiplosItens.vue';
import { objectHasKey } from '@helpers/functions';
import helpers from '@common/utils/helpers';
import _ from 'lodash';

const validators = {
  show: [true, false],
};
const defaultValues = {
  show: false,
  tipos: [],
  modelos: [],
};

export default {
  name: 'TheTipoModeloInstrumentoModal',
  components: {
    BaseStepperModal: () => import('@components/fragments/BaseStepperModal'),
    ColunaMultiplosItens
  },
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      required: true,
    },
    showModal: {
      type: Boolean,
      default: defaultValues.show,
      validator: (value) => {
        return validators.show.includes(value);
      },
    },
    tipos: {
      type: Array,
      default() {
        return defaultValues.tipos;
      },
    },
    modelos: {
      type: Array,
      default() {
        return defaultValues.modelos;
      },
    },
    form: {},
  },
  data() {
    return {
      grandezaFisicaId: null,
      valido: null,
      edicao: false,
      formReferencia: {},
      opcoes: {
        tipoRequisito: helpers.TipoRequisitoEnum,
      },
      currentModalStep: 0,
      modalTitle: this.$t('components.theTipoModeloInstrumentoModal.title'),
      modalTitles: [
        this.$t('components.theTipoModeloInstrumentoModal.steps.one.title'),
        this.$t('components.theTipoModeloInstrumentoModal.steps.two.title'),
      ],
      tableTiposInstrumentosSelected: [],
      includeSelectedTiposInstrumentosItems: false,
      tableTiposInstrumentos: {
        key: 'id',
        headers: [
          {
            text: this.$t(
              'components.theTipoModeloInstrumentoModal.steps.one.table.nome'
            ),
            sortable: true,
            value: 'nome',
          },
          {
            text: this.$t(
              'components.theTipoModeloInstrumentoModal.steps.one.table.tags'
            ),
            sortable: true,
            value: 'tag',
          },
          {
            text: this.$t(
              'components.theTipoModeloInstrumentoModal.steps.one.table.areaAcreditacao'
            ),
            sortable: true,
            value: 'areaAcreditacao.nome',
          },
        ],
        items: [],
        metadata: {
          page: 1,
          totalPages: 1,
          totalItems: null,
          amountPerPage: 10,
        },
      },
      tableModelosInstrumentosSelected: [],
      includeSelectedModelosInstrumentosItems: false,
      tableModelosInstrumentos: {
        key: 'id',
        headers: [
          {
            text: this.$t(
              'components.theTipoModeloInstrumentoModal.steps.two.table.nome'
            ),
            sortable: true,
            value: 'nome',
          },
          {
            text: this.$t(
              'components.theTipoModeloInstrumentoModal.steps.two.table.tipoInstrumento'
            ),
            sortable: true,
            value: 'tipoInstrumento.nome',
          },
          {
            text: this.$t(
              'components.theTipoModeloInstrumentoModal.steps.two.table.marca'
            ),
            sortable: true,
            value: 'marca.nome',
          },
        ],
        items: [],
        metadata: {
          page: 1,
          totalPages: 1,
          totalItems: null,
          amountPerPage: 10,
        },
      },
      tipoModeloInstrumento: {
        nome: '',
        tipo: '',
        modelo: '',
        filter: '',
      },
      tableTipoInstrumentoSelected: [],
      tableModeloInstrumentoSelected: [],
      enabledNextConfirmButton: false,
      concatInstrumentosIds: '',
    };
  },
  watch: {
    showModal(value) {
      if (value) {
        this.openModal();
      } else {
        this.closeModal();
      }
    },
    currentModalStep(value) {
      if (value === 1) {
        this.getTipoInstrumentoList();

        this.tableModelosInstrumentos.items = [];

        if (this.tableTiposInstrumentosSelected.length) {
          this.enabledNextConfirmButton = true;
        } else {
          this.enabledNextConfirmButton = false;
        }
      } else {
        if (this.tableTiposInstrumentosSelected.length) {
          this.enabledNextConfirmButton = true;
        }

        this.concatInstrumentosIds = '';

        this.tableTiposInstrumentosSelected.forEach((item) => {
          if (!this.concatInstrumentosIds) {
            this.concatInstrumentosIds = `tipoInstrumentoIds=${item.id}`;
          } else {
            this.concatInstrumentosIds = `${this.concatInstrumentosIds}&tipoInstrumentoIds=${item.id}`;
          }
        });

        this.getModeloInstrumentoList();
      }
    },
    tableTiposInstrumentosSelected: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value.length) {
          if (this.tableTiposInstrumentos.length) {
            this.setCheckBoxColor('#2E90FA');
          }
          this.enabledNextConfirmButton = true;

          this.updateTableModelosInstrumentosSelected();
        } else {
          this.tableModelosInstrumentosSelected = [];
          this.enabledNextConfirmButton = false;
        }
      },
    },
    tableModelosInstrumentosSelected: {
      deep: true,
      immediate: true,
      handler(value) {
        if (this.currentModalStep === 2) {
          if (value.length) {
            if (this.tableModelosInstrumentos.length) {
              this.setCheckBoxColor('#2E90FA');
            }
            this.enabledNextConfirmButton = true;
          }
        }
      },
    },
    'tipoModeloInstrumento.filter'(value) {
      if (!value) {
        if (this.currentModalStep === 1) {
          this.getTipoInstrumentoList();
        } else {
          this.getModeloInstrumentoList();
        }
      }
    },
    'tableTiposInstrumentos.metadata.page'() {
      this.listaTipoInstrumentos();
    },
    'tableModelosInstrumentos.metadata.page'() {
      this.listaModeloInstrumentos;
    },
  },
  methods: {
    listaTipoInstrumentos(passo, itensPorPagina) {
      let filtro = this.tipoModeloInstrumento?.filter
        ? this.tipoModeloInstrumento?.filter
        : '';
      this.getTipoInstrumentoList(
        filtro,
        passo ? passo : this.tableTiposInstrumentos.metadata.page,
        itensPorPagina ? itensPorPagina : this.tableTiposInstrumentos.metadata.amountPerPage
      );
    },
    MontarViewTags(tags){
      return {
        value: tags.map(v => {
          return {nome: v}
        }),
      }
    },
    listaModeloInstrumentos(passo, itensPorPagina) {
      let filtro = this.tipoModeloInstrumento?.filter
        ? this.tipoModeloInstrumento?.filter
        : '';
      this.getModeloInstrumentoList(
        filtro,
        passo ? passo : this.tableTiposInstrumentos.metadata.page,
        itensPorPagina ? itensPorPagina : this.tableModelosInstrumentos.metadata.amountPerPage
      );
    },
    setCheckBoxColor(color) {
      let item = document.querySelector(
        '.v-input--selection-controls__input span'
      );
      item.style.color = color;
    },
    async filterTipoModelo(type) {
      if (type === 'tipo') {
        await this.getTipoInstrumentoList(
          this.tipoModeloInstrumento.filter
        );
      } else {
        await this.getModeloInstrumentoList(
          this.tipoModeloInstrumento.filter
        );
      }
    },
    async getTipoInstrumentoList(value = '', paginaAtual = 1, porPagina = this.tableTiposInstrumentos.metadata.amountPerPage) {
      const newParams = value ? { filter: value } : {};
      newParams.page = paginaAtual;
      newParams.amountPerPage = porPagina;
      this.tableTiposInstrumentos.metadata.amountPerPage = porPagina;
      await this.buscarGrandezaFisicaId();
      if(this.grandezaFisicaId) newParams.grandezaFisicaId = this.grandezaFisicaId;

      const result = await getTipoInstrumento(newParams);
      if (result.status === 200 && result.data.items.length) {
        this.tableTiposInstrumentos.items = result.data.items;
        this.tableTiposInstrumentos.metadata.totalPages =
          result.data.totalPages;
        this.tableTiposInstrumentos.metadata.totalItems =
          result.data.totalItems;
      } else {
        this.tableTiposInstrumentos.items = [];
      }
    },
    async buscarGrandezaFisicaId(){
      if(!this.form.unidadeMedidaId?.value) return;

      await UnidadeMedidaService.buscar(this.form.unidadeMedidaId?.value).then(
        (response) => {
          this.grandezaFisicaId = response.data.grandezaFisicaId;
        }
      );
    },
    async getModeloInstrumentoList(
      value = '',
      paginaAtual = 1,
      porPagina = this.tableModelosInstrumentos.metadata.amountPerPage
    ) {
      this.tableModelosInstrumentos.metadata.amountPerPage = porPagina;
      let newParams = !value
        ? `${this.concatInstrumentosIds}&page=${paginaAtual}&amountPerPage=${porPagina}`
        : `${this.concatInstrumentosIds}&filter=${value}&page=${paginaAtual}&amountPerPage=${porPagina}`;

      if(this.grandezaFisicaId) newParams = `${newParams}&grandezaFisicaId=${this.grandezaFisicaId}`;
      const result = await getModeloInstrumentoModalTipoModelo(newParams);

      if (result.status === 200 && result.data.items.length) {
        this.tableModelosInstrumentos.items = result.data.items;
        this.tableModelosInstrumentos.metadata.totalPages =
          result.data.totalPages;
        this.tableModelosInstrumentos.metadata.totalItems =
          result.data.totalItems;
      } else {
        this.tableModelosInstrumentos.items = [];
      }
    },
    selectAllTipoInstrumentoListToggle(props) {
      const self = this;

      if (this.tableTipoInstrumentoSelected.length !== props.items.length) {
        props.items.forEach((item) => {
          self.tableTipoInstrumentoSelected.push(item);
        });
      }
    },
    selectAllModeloInstrumentoListToggle(props) {
      const self = this;

      if (this.tableModeloInstrumentoSelected.length !== props.items.length) {
        props.items.forEach((item) => {
          self.tableModeloInstrumentoSelected.push(item);
        });
      }
    },
    updateTableModelosInstrumentosSelected: async function() {
      await this.$nextTick();
      if (this.tableModelosInstrumentosSelected.length) {
        const tiposIdList = this.tableTiposInstrumentosSelected.map(
          (item) => item.id
        );
        const newModelosList = this.tableModelosInstrumentosSelected.filter(
          (item) => tiposIdList.includes(item.tipoInstrumento.id)
        );

        this.tableModelosInstrumentosSelected = newModelosList;
      }
    },
    clearModal() {
      this.tipoModeloInstrumento = {
        nome: '',
        tipo: '',
        modelo: '',
        filter: '',
      };
      this.tableTiposInstrumentos.items = [];
      this.tableTiposInstrumentosSelected = [];
      this.tableModelosInstrumentos.items = [];
      this.tableModelosInstrumentosSelected = [];
    },
    openModal() {
      if (this.tipos.length && this.modelos.length) {
        this.tableTiposInstrumentosSelected = this.tipos;
        this.tableModelosInstrumentosSelected = this.modelos?.filter(
          (el) => el
        );
        this.currentModalStep = 1;
      } else {
        this.tableTiposInstrumentosSelected = [];
        this.tableModelosInstrumentosSelected = [];
        this.currentModalStep = 1;
      }

      this.getTipoInstrumentoList();
    },
    closeModal() {
      this.clearModal();
      this.currentModalStep = 0;
      this.$emit('close-modal', true);
    },
    cleanTipos() {
      const tiposList = [];
      this.tableTiposInstrumentosSelected.forEach((item) => {
        if (objectHasKey(item, 'id')) tiposList.push(item);
      });
      return tiposList;
    },
    changeModalStep(value, newStep = '') {
      if (newStep && newStep === 'last') {
        const newTipos = this.cleanTipos();
        this.$emit('change-values', {
          modelos: _.uniqBy(this.tableModelosInstrumentosSelected, 'id'),
          tipos: _.uniqBy(newTipos, 'id'),
          step: newStep,
        });
        this.closeModal();
      } else {
        this.currentModalStep = value;
      }

      this.tipoModeloInstrumento.filter = '';
    },
  },
};
</script>
<style lang="scss" scoped>
.paginacao-padrao {
  .v-pagination__navigation,
  .v-pagination__item {
    box-shadow: none !important;
  }
}
// :deep(.p-checkbox .p-checkbox-box) {
//   margin-top: 2px;
//   width: 16px !important;
//   height: 16px !important;
// }

:deep(.p-dropdown-items) {
  z-index: 1500 !important;
  position: fixed;
  overflow-y: scroll;
  height: 150px;
  padding: 0.5rem 0;
  background: #ffffff;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
</style>
