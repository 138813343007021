<template>
  <div ref="tipo-modelo-instrumento">
    <div class="d-flex align-center justify-end">
      <tipo-modelo-instrumento-modal
        id="TipoModeloInstrumentoModal"
        :show-modal="showModal"
        :tipos="tabela.itens.tipos"
        :modelos="tabela.itens.modelos"
        :form="form"
        @close-modal="showModal = false"
        @change-values="changeModalStep"
      />
      <botao-padrao class="mt-3" @click="abrirNovo">
        {{
          $t(
            'modulos.servico_calibracao.formulario.tipo_modelo_instrumento.botoes.novo'
          )
        }}
      </botao-padrao>
    </div>
    <v-container fluid>
      <v-data-table
        id="virtual-scroll-table"
        ref="table"
        v-model="tabela.selecionados"
        :headers="tabela.headers"
        :items="form.itemTipoInstrumentoModeloInstrumento"
        :items-per-page="form.itemTipoInstrumentoModeloInstrumento.length"
        group-by="tipoInstrumento.nome"
        show-group-by
        hide-default-footer
        :no-data-text="
          $t('modulos.item.tipo_modelo_instrumento.sem_informacao')
        "
        @toggle-select-all="selectAllToggle($event)"
      >
        <!-- eslint-disable vue/valid-v-slot-->
        <template
          v-slot:group.header="{ group, items, headers, toggle, isOpen }"
        >
          <td :colspan="headers.length">
            <div class="d-flex align-center" style="font-size: 16px">
              <v-btn icon color="primary" @click="toggle">
                <v-icon>
                  {{
                    isOpen
                      ? $vuetify.icons.values.minusBox
                      : $vuetify.icons.values.plusBox
                  }}
                </v-icon>
              </v-btn>

              <div>
                <span class="w500">
                  {{ group }}
                  <span class="ml-1 w400">
                    {{
                      $tc(
                        'components.baseTable.itemGroupLength',
                        items.length,
                        { count: items.length }
                      )
                    }}
                  </span>
                </span>
              </div>
            </div>
          </td>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td style="padding-left: 35px">
              {{ item.modeloInstrumento?.nome ?? 'Todos os Modelos Referentes' }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>
<script>
import TipoModeloInstrumentoModal from '../../servico_calibracao/components/modais/TipoModeloInstrumentoModal';

export default {
  components: { TipoModeloInstrumentoModal },
  props: {
    form: {},
  },
  data() {
    return {
      showModal: false,
      tabela: {
        chave: 'id',
        selecionados: [],
        headers: [
          {
            text: this.$t('modulos.item.tabela.modelos_instrumentos'),
            align: 'start',
            value: 'modeloInstrumento.nome',
            groupable: false,
          },
          {
            text: this.$t('modulos.item.tabela.tipos_instrumentos'),
            align: 'start',
            value: 'tipoInstrumento.nome',
          },
        ],
        itens: {
          tipos: [],
          modelos: [],
        },
      },
    };
  },
  watch: {
    form: {
      handler() {},
      deep: true,
    },
  },
  methods: {
    abrirNovo: function () {
      this.tabela.itens.modelos =
        this.form.itemTipoInstrumentoModeloInstrumento.map((item) => {
          if (!item.modeloInstrumento) return;
          return {
            id: item.modeloInstrumento.id,
            nome: item.modeloInstrumento.nome,
            tipoInstrumento: item.tipoInstrumento,
          };
        });
      this.tabela.itens.tipos =
        this.form.itemTipoInstrumentoModeloInstrumento.map(
          (item) => item.tipoInstrumento
        );
      this.showModal = true;
    },
    salvarNovoTipoModeloInstrumento: function (form) {
      this.form.itemTipoInstrumentoModeloInstrumento.push(form);
      form.ordem = this.form.itemTipoInstrumentoModeloInstrumento.length;
    },
    closeModal() {
      this.showModal = false;
    },
    changeModalStep(value) {
      if (value.step === 'last') {
        this.form.itemTipoInstrumentoModeloInstrumento = [];
        value.tipos.forEach((tipo) => {
          const modelos = value.modelos.filter(
            (el) => el.tipoInstrumento.id === tipo.id
          );

          if (!modelos?.length) {
            this.form.itemTipoInstrumentoModeloInstrumento.push({
              tipoInstrumentoId: tipo.id,
              tipoInstrumento: {
                id: tipo.id,
                nome: tipo.nome,
              },
              modeloInstrumentoId: null,
              modeloInstrumento: null,
            });
          }

          modelos?.forEach((modelo) => {
            this.form.itemTipoInstrumentoModeloInstrumento.push({
              tipoInstrumentoId: tipo.id,
              tipoInstrumento: {
                id: tipo.id,
                nome: tipo.nome,
              },
              modeloInstrumentoId: modelo.id,
              modeloInstrumento: {
                id: modelo.id,
                nome: modelo.nome,
              },
            });
          });
        });
      } else {
        this.form.itemTipoInstrumentoModeloInstrumento = [];
      }
      this.closeModal();
    },
  },
};
</script>
